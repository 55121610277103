import { Add } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { ArrayInput, SimpleFormIterator, TextInput } from "react-admin";

const CollaboratorStepFive = ({ activeStep, currentStep, goToNextStep }) => {
  useEffect(() => {
    if (activeStep === currentStep) {
      goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep]);

  return (
    <>
      <ArrayInput
        source="collaboratorCustomFields"
        label={false}
        name={"collaboratorCustomFields"}
        resource={"collaborator_custom_fields"}
      >
        <SimpleFormIterator
          inline
          disableReordering
          addButton={<Button startIcon={<Add />}>Nouveau champ</Button>}
        >
          <TextInput label={"Titre"} source="title" helperText={false} />
          <TextInput label={"Valeur"} source="value" helperText={false} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export default CollaboratorStepFive;
