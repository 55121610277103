import { LoadingButton } from "@mui/lab";
import { CircularProgress, Divider, Grow, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LinearProgressWithLabel from "admin/components/general/ui/LinearProgressWithLabel";
import { entrypoint, httpClient } from "admin/config/dataProvider";
import {
  mediumPassword,
  passwordValidation,
  strongPassword,
} from "admin/const/validation";
import React, { useEffect, useState } from "react";
import { PasswordInput, SimpleForm, useLogin, useNotify } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { Link, Navigate, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const passwordStrengthFormatter = {
  strong: {
    color: "success",
    label: "Fort",
  },
  medium: {
    color: "warning",
    label: "Suffisant",
  },
  low: {
    color: "error",
    label: "Insuffisant",
  },
};

export const LengthAndStrengthIndicator = ({ setCanCheck }) => {
  const { plainPassword } = useWatch();
  const [minimumLengthProgression, setMinimumLengthProgression] = useState(0);
  const [passwordStrength, setPasswordStrength] = useState("low");

  useEffect(() => {
    const rawMinimumProgression = (plainPassword?.length / 8) * 100;
    const minimumProgression =
      rawMinimumProgression <= 100 ? rawMinimumProgression : 100;

    let passwordStrength = "low";
    if (strongPassword.test(plainPassword)) {
      passwordStrength = "strong";
    } else {
      if (mediumPassword.test(plainPassword)) {
        passwordStrength = "medium";
      }
    }

    setMinimumLengthProgression(minimumProgression);
    setPasswordStrength(passwordStrength);

    if (minimumProgression >= 100 && passwordStrength !== "low") {
      setCanCheck(true);
    } else {
      setCanCheck(false);
    }
  }, [plainPassword]);

  return (
    <LinearProgressWithLabel
      color={passwordStrengthFormatter[passwordStrength]["color"]}
      value={minimumLengthProgression}
      label={passwordStrengthFormatter[passwordStrength]["label"]}
    />
  );
};

export const PasswordValidator = ({ disabled, setCanSubmit }) => {
  const { plainPassword, passwordCheck } = useWatch();
  const { setError, clearErrors } = useFormContext();

  useEffect(() => {
    if (plainPassword !== passwordCheck) {
      setError("passwordCheck", {
        message: "Les mots de passe ne correspondent pas",
      });
      setCanSubmit(false);
    } else {
      clearErrors();
      setCanSubmit(true);
    }
  }, [plainPassword, passwordCheck]);

  return (
    <PasswordInput
      size={"large"}
      fullWidth
      name={"passwordCheck"}
      source={"passwordCheck"}
      required
      error
      disabled={disabled}
      label={"Resaisir le mot de passe"}
    />
  );
};

const ResetPasswordSection = () => {
  const query = useQuery();
  const token = query.get("token");
  const expires = query.get("expires") || 0;
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [canCheck, setCanCheck] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showLoginSpinner, setShowLoginSpinner] = useState(false);
  const notify = useNotify();
  const login = useLogin();

  useEffect(() => {
    const expireDate = new Date(expires * 1000);
    setExpired(expireDate < new Date());
  }, [setExpired]);

  if (!token || expired) {
    notify("Le lien n'est pas ou plus valide", { type: "error" });
    return <Navigate to={"/login"} />;
  }

  const handleSubmit = ({ plainPassword }) => {
    setLoading(true);
    httpClient(
      `${entrypoint}/security/password/reset`,
      {
        method: "POST",
        body: JSON.stringify({ plainPassword, token }),
      },
      true
    )
      .then(({ json }) => {
        const { email } = json;
        setLoading(false);
        setSuccess(true);

        setTimeout(() => {
          login({ email, password: plainPassword }).then((r) => true);
        }, 3000);
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
      });
  };

  return (
    <Box mb={5}>
      <Paper elevation={10} sx={{ backgroundColor: "rgba(255,255,255,0.5)" }}>
        <Box p={4}>
          <Grow
            in={!success}
            unmountOnExit={true}
            onExited={() => setShowLoginSpinner(true)}
          >
            <div>
              <Typography
                gutterBottom
                align={"center"}
                variant={"h5"}
                fontWeight={"bolder"}
              >
                Vous y êtes presque ! 😀
              </Typography>
              <Typography align={"center"}>
                Clic clac clouc, un nouveau mot de passe et en route !
              </Typography>
              <Typography variant={"caption"} component={"p"} align={"center"}>
                8 caractères avec 1 majuscule et 1 chiffre minimum
              </Typography>
              <SimpleForm
                toolbar={false}
                reValidateMode={"onChange"}
                onSubmit={handleSubmit}
              >
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <PasswordInput
                      aria-autocomplete={"none"}
                      validate={passwordValidation}
                      disabled={loading}
                      size={"large"}
                      fullWidth
                      name={"plainPassword"}
                      source={"plainPassword"}
                      label={"Mot de passe"}
                      helperText={
                        <LengthAndStrengthIndicator setCanCheck={setCanCheck} />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <PasswordValidator
                      disabled={!canCheck || loading}
                      setCanSubmit={setCanSubmit}
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  type="submit"
                  fullWidth
                  size={"large"}
                  disabled={!canSubmit}
                  loading={loading}
                  variant={"outlined"}
                >
                  Réinitialiser mon mot de passe
                </LoadingButton>
              </SimpleForm>
              <Box mb={2}>
                <Divider />
              </Box>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <Button component={Link} to={"/login"}>
                    Revenir à la connexion
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grow>
          <Grow in={showLoginSpinner}>
            <Box>
              <Grid container direction={"column"} alignItems={"center"}>
                <Grid item>
                  <Typography variant={"h5"} fontWeight={"bolder"}>
                    🎉 Mot de passe réinitialisé 🎉
                  </Typography>
                </Grid>
                <Grid item>
                  <Box mt={3}>
                    <CircularProgress size={75} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography>Reconnexion</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grow>
        </Box>
      </Paper>
    </Box>
  );
};

export default ResetPasswordSection;
