import { HomeOutlined, PhoneOutlined } from "@mui/icons-material";
import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/styles";
import { createLinearGradient } from "admin/const/graph";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useRecordContext } from "react-admin";
import { Doughnut } from "react-chartjs-2";

const chartOptions = {
  events: [],
};

const data = {
  labels: [],
  datasets: [
    {
      data: [100],
      borderWidth: 0,
      rotation: 180,
      backgroundColor: grey[100],
      radius: "75%",
      cutout: "80%",
      borderRadius: 0,
    },
  ],
};

const ContractProgress = () => {
  const chartRef = useRef();
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const contract = useRecordContext();
  const {
    palette: { primary, secondary },
  } = useTheme();
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart || !contract?.timeSpent) {
      return;
    }

    const {
      totalHours,
      timeSpent: { total },
    } = contract;
    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        data: [total, totalHours - total],
        borderRadius: 40,
        backgroundColor: [
          createLinearGradient(chart.ctx, chart.chartArea, [
            primary.main,
            secondary.main,
          ]),
          "rgba(0,0,0,0)",
        ],
      })),
    };

    setChartData(chartData);
  }, [chartRef, setChartData, contract]);

  const inPersonTime = contract?.timeSpent?.physical;
  const remoteTime = contract?.timeSpent?.remote;
  const totalSpent = contract?.timeSpent?.total;
  const totalHours = contract?.totalHours;
  return (
    <Paper variant={"outlined"} sx={{ height: "100%" }}>
      <Box sx={{ position: "relative" }}>
        <Doughnut
          style={{ position: "relative", zIndex: 2 }}
          ref={chartRef}
          data={chartData}
          options={chartOptions}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <Doughnut data={data} options={chartOptions} />
        </Box>
        <Grid
          container
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
          }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item>
            <Typography variant={"h6"} align={"center"}>
              Temps utilisé
            </Typography>
            <Typography variant={"h3"} align={"center"} fontWeight={"bolder"}>
              {moment.duration(totalSpent, "hours").format("HH:mm")}
            </Typography>
            <Typography variant={"h5"} align={"center"}>
              / {moment.duration(totalHours, "hours").format("HH:mm")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box px={2} pb={2}>
        <Grid container alignItems={"center"}>
          <Grid item xs={6}>
            <Box>
              <ListItem>
                <ListItemIcon>
                  <HomeOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={moment
                    .duration(inPersonTime, "hours")
                    .format("HH:mm")}
                  secondary={"Sur site"}
                />
              </ListItem>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box arrow>
              <ListItem>
                <ListItemIcon>
                  <PhoneOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={moment.duration(remoteTime, "hours").format("HH:mm")}
                  secondary={"Distanciel"}
                />
              </ListItem>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ContractProgress;
