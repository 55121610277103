import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import BaseForm from "admin/components/general/input/ReferenceCreatorInput/forms";
import {
  Create,
  SaveButton,
  SimpleForm,
  useCreateSuggestionContext,
} from "react-admin";

const ReferenceDialogInput = ({
  reference,
  title = "Nouvel élément",
  defaultValues,
  form: CreatorForm = BaseForm,
}) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const transformValues = (data) => ({
    ...data,
    ...defaultValues,
  });

  const mutationOptions = {
    onSuccess: (data) => {
      onCreate(data);
    },
  };

  return (
    <Create
      component={"div"}
      resource={reference}
      redirect={false}
      transform={transformValues}
      mutationOptions={mutationOptions}
    >
      <Dialog open onClose={onCancel}>
        <DialogTitle>{`${title}`}</DialogTitle>
        <DialogContent>
          <SimpleForm
            id={`create_${reference}_form`}
            toolbar={false}
            component="div"
          >
            <Box mt={1} sx={{ width: "100%" }}>
              <CreatorForm filter={filter} defaultValues={defaultValues} />
            </Box>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="error">
            Annuler
          </Button>
          <SaveButton form={`create_${reference}_form`} />
        </DialogActions>
      </Dialog>
    </Create>
  );
};

export default ReferenceDialogInput;
