import {
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import CloseDialogButton from "admin/components/general/ui/dialog/CloseDialogButton";
import { firstnameValidation } from "admin/const/validation";
import { SaveButton, TextInput } from "react-admin";

export const DepartmentFormContainer = ({
  formId,
  handleClose,
  department,
}) => {
  return (
    <>
      <CloseDialogButton handleClose={handleClose} />
      <DialogTitle align={"center"} fontWeight={"bolder"}>
        Créer un nouveau service
      </DialogTitle>
      {department && (
        <Alert severity={"info"}>
          Ce service sera rattaché au service "{department?.name}"
        </Alert>
      )}
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <TextInput
            validate={firstnameValidation}
            name={"name"}
            source={"label"}
            size={"large"}
            fullWidth
            label={"Nom du service"}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <SaveButton
          type={"button"}
          form={formId}
          label={"Créer le service"}
          variant={"text"}
        ></SaveButton>
      </DialogActions>
    </>
  );
};
