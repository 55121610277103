import React from "react";
import { FilterListItem, useGetList } from "react-admin";

const CompanyFilterItem = ({ company: { "@id": IRI, name } }) => {
  return (
    <FilterListItem label={name} value={{ "collaborator.company": IRI }} />
  );
};

export const CompanyFilterIterator = React.memo(() => {
  const { data, isLoading } = useGetList("companies");
  return (
    <>
      {!isLoading &&
        data.map((company) => (
          <CompanyFilterItem
            company={company}
            key={`filter_${company["@id"]}`}
          />
        ))}
    </>
  );
});

const ThematicFilterItem = ({ thematic: { "@id": IRI, label } }) => {
  return <FilterListItem label={label} value={{ "folder.thematic": IRI }} />;
};

export const ThematicFilterIterator = React.memo(() => {
  const { data, isLoading } = useGetList("thematics");
  return (
    <>
      {!isLoading &&
        data.map((thematic) => (
          <ThematicFilterItem
            thematic={thematic}
            key={`filter_${thematic["@id"]}`}
          />
        ))}
    </>
  );
});
