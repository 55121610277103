import { Box, Grid, Paper, Typography } from "@mui/material";
import BooleanTextField from "admin/components/general/fields/BooleanTextField";
import { DateField, TextField, useRecordContext } from "react-admin";

const CardField = ({
  icon: Icon,
  label,
  source,
  type = "text",
  additionalProps = {},
}) => {
  let Component;
  const record = useRecordContext();
  switch (type) {
    case "boolean":
      Component = BooleanTextField;
      break;
    case "date":
      Component = DateField;
      break;
    default:
      Component = TextField;
      break;
  }

  if (!record[source]) {
    return <></>;
  }
  return (
    <Paper variant={"outlined"}>
      <Grid container alignItems={"center"}>
        <Grid item>
          <Box p={1}>
            <Icon fontSize={"small"} />
          </Box>
        </Grid>
        <Grid item>
          <Box p={1}>
            <Component source={source} {...additionalProps} />
            <Typography component={"p"} variant={"caption"}>
              {label}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardField;
