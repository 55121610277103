import { ResourceEditor } from "admin/components/form/ResourceForm";
import { useCallback } from "react";
import { RecordContextProvider, useRecordContext } from "react-admin";

const ReminderEditor = ({ showEdit, setShowEdit }) => {
  const record = useRecordContext();

  const handleShowEditClose = useCallback(() => {
    setShowEdit(false);
  }, [setShowEdit]);

  return record ? (
    <RecordContextProvider
      value={{
        ...record,
        type: record.type["@id"],
        collaborator: record.collaborator["@id"],
        user: record.user["@id"],
      }}
    >
      <ResourceEditor
        FormContainerProps={{
          open: showEdit,
          maxWidth: "md",
          fullWidth: true,
          onClose: handleShowEditClose,
        }}
        FormProps={{ handleClose: handleShowEditClose }}
      />
    </RecordContextProvider>
  ) : (
    <></>
  );
};

export default ReminderEditor;
