import DeleteButton from "admin/components/general/buttons/DeleteButton";
import EditButton from "admin/components/general/buttons/EditButton";
import DurationField from "admin/components/general/fields/DurationField";
import InterventionExpand from "admin/components/resources/interventions/list/InterventionExpand";
import { Datagrid, DateField, ReferenceField, TextField } from "react-admin";

const InterventionListLayout = ({ disabled }) => {
  return (
    <Datagrid
      bulkActionButtons={false}
      stickyHeader={false}
      expand={<InterventionExpand />}
    >
      <DateField source="date" />
      <ReferenceField
        source="type"
        reference="intervention_types.id"
        label={"Libellé"}
        link={false}
      >
        <TextField source="label" />
      </ReferenceField>
      <DurationField
        showDate={false}
        showTime
        source={"duration"}
        label={"Temps"}
      />
      <EditButton intervention disabled={disabled} />
      <DeleteButton resource={"intervention"} disabled={disabled} />
    </Datagrid>
  );
};

export default InterventionListLayout;
