import { TextInput } from "react-admin";

const BaseForm = ({ filter }) => {
  return (
    <TextInput
      source={"name"}
      label="Dénomination"
      autoFocus
      defaultValue={filter}
      fullWidth
      helperText={false}
    />
  );
};

export default BaseForm;
