import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useCallback } from "react";
import {
  CreateBase,
  FileField,
  FileInput,
  SaveButton,
  SimpleForm,
  useNotify,
  useRefresh,
} from "react-admin";

const FileFormContent = () => {
  return (
    <Grid container direction={"column"} alignItems={"center"}>
      <Grid item>
        <FileInput
          name={"file"}
          source={"file"}
          label={false}
          accept={"application/pdf"}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </Grid>
    </Grid>
  );
};

const FileFormDialog = ({ resource, onClose, open }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const handleFileUploadSuccess = useCallback(() => {
    refresh();
    notify("Fichier ajouté", { type: "success" });
    onClose();
  }, [notify, refresh, onClose]);

  const handleFileUploadError = useCallback(() => {
    refresh();
    notify("Le fichier n'a pas pu être ajouté", { type: "error" });
    onClose();
  }, [notify, refresh, onClose]);

  return (
    <CreateBase
      mutationOptions={{
        onSuccess: handleFileUploadSuccess,
        onError: handleFileUploadError,
      }}
      redirect={false}
      resource={resource}
    >
      <SimpleForm id={`${resource}_file_upload`} toolbar={false}>
        <Dialog open={open}>
          <DialogTitle>Nouveau fichier</DialogTitle>
          <DialogContent>
            <FileFormContent />
          </DialogContent>
          <DialogActions>
            <Button color={"error"} onClick={onClose}>
              Annuler
            </Button>
            <SaveButton
              form={`${resource}_file_upload`}
              label={"Envoyer le fichier"}
            />
          </DialogActions>
        </Dialog>
      </SimpleForm>
    </CreateBase>
  );
};

export default FileFormDialog;
