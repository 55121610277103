import React from 'react';
import {CircularProgress, Grid} from "@mui/material";
import CreatedCollaborator from "./widget/CreatedCollaborator";
import AverageInterventionByCollaborator from "./widget/AverageInterventionByCollaborator";
import AverageInterventionTime from "./widget/AverageInterventionTime";
import AppointmentTotal from "./widget/AppointmentTotal";
import ContractProgress from "./graph/ContractProgress";
import ThematicRepartition from "./graph/ThematicRepartition";
import {useRecordContext, useShowContext} from "react-admin";

const StatLayout = () => {
    const {isLoading, record} = useShowContext();
    console.log(record);
    return (
        <div>
            {!isLoading && record ?
            <Grid container direction={"column"} spacing={2}>
                <Grid item>
                    <Grid container spacing={5} alignItems={"stretch"}>
                        <Grid item md={3}>
                            <CreatedCollaborator/>
                        </Grid>
                        <Grid item md={3}>
                            <AverageInterventionByCollaborator/>
                        </Grid>
                        <Grid item md={3}>
                            <AverageInterventionTime/>
                        </Grid>
                        <Grid item md={3}>
                            <AppointmentTotal/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={5} alignItems={"stretch"}>
                        <Grid item lg={4}>
                            <ContractProgress/>
                        </Grid>
                        <Grid item lg={8}>
                            <ThematicRepartition/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                :
                <CircularProgress />
            }
        </div>
    );
};

export default StatLayout;