import { Alert, AlertTitle, CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import BudgetSummary from "admin/components/resources/collaborator/show/budget/BudgetSummary";
import FileSection from "admin/components/resources/collaborator/show/files/FileSummary";
import InfoCard from "admin/components/resources/collaborator/show/infos/InfoCard";
import InterventionSection from "admin/components/resources/collaborator/show/interventions/InterventionSummary";
import ReminderSummary from "admin/components/resources/collaborator/show/reminder/ReminderSummary";
import { useEffect } from "react";
import { useRecordContext, useShowContext } from "react-admin";

const CollaboratorContainer = () => {
  const record = useRecordContext();
  const { isLoading, refetch } = useShowContext();
  useEffect(() => {
    refetch();
  }, [refetch]);
  return (
    <>
      {record && !isLoading ? (
        <Grid container direction={"column"}>
          {!record.active && (
            <Grid item>
              <Box mb={2}>
                <Alert severity={"error"} variant={"outlined"}>
                  <AlertTitle>Collaborateur désactivé</AlertTitle>
                  La fiche de ce collaborateur est en lecture seule uniquement.
                </Alert>
              </Box>
            </Grid>
          )}
          <Grid item>
            <InfoCard editable />
          </Grid>
          <Grid item>
            <Grid container spacing={5} alignItems={"stretch"}>
              <Grid item md={4}>
                <ReminderSummary />
              </Grid>
              <Grid item md={4}>
                <BudgetSummary />
              </Grid>
              <Grid item md={4}>
                <FileSection />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <InterventionSection disabled={!record.active} />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CollaboratorContainer;
