import {
  Box,
  Button,
  Grid,
  Paper,
  Tooltip as MuiTooltip,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import BudgetDialog from "admin/components/resources/collaborator/show/budget/index";
import React, { useCallback, useEffect, useState } from "react";
import { useRecordContext, useRefresh } from "react-admin";
import { Doughnut } from "react-chartjs-2";

const graphOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const budgetShowConfiguration = {
  labels: ["Revenus", "Charges"],
  datasets: [
    {
      label: "Budget",
      data: [0, 0],
      backgroundColor: [green[300], red[300]],
      borderWidth: 5,
    },
  ],
};

const BudgetSummary = () => {
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const { incomes, outcomes } = useRecordContext();
  const [graphData, setGraphData] = useState(budgetShowConfiguration);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    refresh();
  }, [setOpen, refresh]);

  useEffect(() => {
    const data = [incomes, outcomes];
    setGraphData({
      ...budgetShowConfiguration,
      datasets: [
        {
          ...budgetShowConfiguration.datasets[0],
          data,
        },
      ],
    });
  }, [incomes, outcomes]);

  return (
    <Paper variant={"outlined"} sx={{ height: "100%" }}>
      <MuiTooltip title={"Gérer le budget"} arrow>
        <Button
          sx={{ width: "100%", height: "100%" }}
          variant={"text"}
          onClick={handleOpen}
        >
          <Box p={2}>
            <Grid container direction={"column"} alignItems={"center"}>
              <Grid item>
                <Typography align={"center"} variant={"overline"}>
                  Balance : {(incomes - outcomes).toFixed(2)} €
                </Typography>
              </Grid>
              <Grid item>
                {incomes === 0 && outcomes === 0 ? (
                  <Button>Définir le budget</Button>
                ) : (
                  <div
                    style={{ height: 100, width: 100, position: "relative" }}
                  >
                    <Doughnut
                      options={graphOptions}
                      height={7}
                      data={graphData}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </Button>
      </MuiTooltip>
      <BudgetDialog open={open} handleClose={handleClose} />
    </Paper>
  );
};

export default React.memo(BudgetSummary);
