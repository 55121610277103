import { Box, DialogContentText } from "@mui/material";
import { requiredValidation } from "admin/const/validation";
import { useEffect } from "react";
import { DateInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = ["sendAt"];

const ReminderStepOne = ({ activeStep, goToNextStep, currentStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();
  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });
      if (canGoNext) goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Pour quelle date ?
      </DialogContentText>
      <Box mt={2}>
        <DateInput
          fullWidth
          required
          name={"sendAt"}
          label={"Pour le"}
          validate={requiredValidation}
        />
      </Box>
    </>
  );
};

export default ReminderStepOne;
