import { CheckCircleOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import StepperForm from "admin/components/form/StepperForm";
import InterStepFour from "admin/components/resources/interventions/form/steps/InterStepFour";
import InterStepOne from "admin/components/resources/interventions/form/steps/InterStepOne.js";
import InterStepThree from "admin/components/resources/interventions/form/steps/InterStepThree";
import InterStepTwo from "admin/components/resources/interventions/form/steps/InterStepTwo";
import { useCallback } from "react";
import { useRefresh } from "react-admin";
import { useFormContext } from "react-hook-form";

const formSteps = [
  {
    title: "Date",
    stepComponent: InterStepOne,
    add: true,
    edit: true,
  },
  {
    title: "Intervention",
    stepComponent: InterStepTwo,
    add: true,
    edit: true,
  },
  {
    title: "Personnes concernées",
    stepComponent: InterStepThree,
    add: true,
    edit: true,
  },
  {
    title: "Informations complémentaires",
    stepComponent: InterStepFour,
    add: true,
    edit: true,
  },
];

const CreatedInterContent = ({ handleClose }) => {
  const { reset } = useFormContext();
  const refresh = useRefresh();
  const handleCloseCallback = useCallback(() => {
    reset();
    handleClose();
    refresh();
  }, [reset, handleClose, refresh]);
  return (
    <>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item>
          <Box mb={2} color={"success.light"}>
            <CheckCircleOutlined color={"inherit"} sx={{ fontSize: 75 }} />
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2}>
            <Typography fontWeight={"bolder"} gutterBottom>
              Intervention enregistrée !
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button onClick={handleCloseCallback} variant={"text"} size={"small"}>
            Fermer
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const InterFormContainer = ({ formId, handleClose }) => {
  return (
    <Box p={2}>
      <StepperForm
        formId={formId}
        formSteps={formSteps}
        formTitle={"Nouvelle intervention"}
        afterSuccessContent={<CreatedInterContent handleClose={handleClose} />}
      />
    </Box>
  );
};
