import { Box, Button, Grid } from "@mui/material";
import DownloadFileField from "admin/components/general/fields/DownloadFileField";
import ViewFileField from "admin/components/general/fields/ViewFileField";
import FileFormDialog from "admin/components/resources/files/form";
import FileEmptyList from "admin/components/resources/files/list/FileEmptyList";
import { useCallback, useState } from "react";
import { Datagrid, ListBase, TextField } from "react-admin";

const FileList = ({ resource, filter = {} }) => {
  const [openNewFile, setOpenNewFile] = useState(false);

  const handleFileFormOpen = useCallback(() => {
    setOpenNewFile(true);
  }, [setOpenNewFile]);

  const handleFileFormClose = useCallback(() => {
    setOpenNewFile(false);
  }, [setOpenNewFile]);

  return (
    <>
      <FileFormDialog
        open={openNewFile}
        onClose={handleFileFormClose}
        resource={resource}
      />
      <ListBase
        filter={filter}
        resource={resource}
        component={"div"}
        actions={false}
        empty={<FileEmptyList resource={resource} />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            sx={{ width: 150, overflow: "hidden", textOverflow: "ellipsis" }}
            component={"p"}
            noWrap
            source={"originalName"}
            label={"Fichier"}
          />
          <DownloadFileField source={"publicUrl"} label={false} />
          <ViewFileField source={"publicUrl"} label={false} />
        </Datagrid>
      </ListBase>
      <Box p={4}>
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Button variant={"contained"} onClick={handleFileFormOpen}>
              Ajouter un fichier
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FileList;
