import {Box, DialogContentText, Grid} from "@mui/material";
import {requiredValidation} from "admin/const/validation";
import {useEffect} from "react";
import {DateInput, TextInput} from "react-admin";
import {useFormContext, useWatch} from "react-hook-form";

const requiredFields = ["startDate", "endDate", "physicalHours", "remoteHours"];

const CompanyStepTwo = ({activeStep, goToNextStep, currentStep}) => {
    const formValues = useWatch();
    const {formState, getFieldState} = useFormContext();
    const fieldValidation = requiredFields.map((field) => {
        const {error} = getFieldState(field, formState);
        return {
            field,
            error,
            empty: !formValues[field],
        };
    });

    useEffect(() => {
        if (activeStep === currentStep && formValues !== {}) {
            let canGoNext = true;
            fieldValidation.forEach((item) => {
                if (item.empty || !!item.error) {
                    canGoNext = false;
                }
            });
            if (canGoNext) goToNextStep();
        }
    }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

    return (
        <>
            <DialogContentText align={"center"} variant={"overline"}>
                Quelle est la période effective du contrat ?
            </DialogContentText>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <DateInput
                            size={"large"}
                            validate={requiredValidation}
                            fullWidth
                            name={"startDate"}
                            source={"startDate"}
                            label={"Début"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DateInput
                            size={"large"}
                            validate={requiredValidation}
                            fullWidth
                            name={"endDate"}
                            source={"endDate"}
                            label={"Fin"}
                        />
                    </Grid>
                </Grid>
            </Box>
            <DialogContentText align={"center"} variant={"overline"}>
                Heures négociées
            </DialogContentText>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            type={"number"}
                            size={"large"}
                            validate={requiredValidation}
                            fullWidth
                            name={"physicalHours"}
                            source={"physicalHours"}
                            label={"Présentiel"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            type={"number"}
                            size={"large"}
                            validate={requiredValidation}
                            fullWidth
                            name={"remoteHours"}
                            source={"remoteHours"}
                            label={"Distanciel"}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default CompanyStepTwo;
