import { Tooltip } from "@mui/material";
import { priorityArray } from "admin/const/priority";
import { useRecordContext } from "react-admin";

const ReminderIconField = ({ source, ...iconProps }) => {
  const record = useRecordContext();
  const { label, icon: Icon } = priorityArray[record[source]];
  return (
    <Tooltip title={label} arrow>
      <Icon fontSize={"small"} {...iconProps} />
    </Tooltip>
  );
};

export default ReminderIconField;
