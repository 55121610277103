import { Box, DialogContentText, Grid, ListItemText } from "@mui/material";
import { requiredValidation } from "admin/const/validation";
import { useEffect } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = ["collaborator", "user"];

const ReminderStepThree = ({ activeStep, currentStep, goToNextStep }) => {
  const formValues = useWatch();
  const { user } = useWatch();
  const { formState, getFieldState } = useFormContext();
  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });
      if (canGoNext) goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Collaborateur et utilisateur concernés
      </DialogContentText>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mb={1}>
              <ReferenceInput
                name={"users"}
                source={"user"}
                reference={"users"}
              >
                <AutocompleteInput
                  validate={requiredValidation}
                  label={"Utilisateur"}
                  optionValue="id"
                  optionText={(record) =>
                    record.lastname + " " + record.firstname
                  }
                />
              </ReferenceInput>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={1}>
              <ReferenceInput
                name={"collaborators"}
                source={"collaborator"}
                reference={"collaborators"}
                filter={{ user: user }}
              >
                <AutocompleteInput
                  validate={requiredValidation}
                  label={"Collaborateur"}
                  optionValue="id"
                  filterToQuery={(search) => ({ lastname: search })}
                  optionText={(record) => (
                    <ListItemText
                      primary={`${record.fullName}`}
                      secondary={record.company.name}
                    />
                  )}
                  inputText={(record) => record.fullName}
                  disabled={!user}
                />
              </ReferenceInput>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReminderStepThree;
