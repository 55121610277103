const ROLE_ADMIN = "ROLE_ADMIN";
const ROLE_DASHBOARD = "ROLE_DASHBOARD";

export const isAdmin = (roles) => {
  return !roles || roles.includes(ROLE_ADMIN);
};

export const isDashboard = (roles) => {
  return roles.includes(ROLE_DASHBOARD);
};
