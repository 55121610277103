import {
  Cake,
  ChildCare,
  Edit,
  Email,
  House,
  Phone,
  PinDrop,
  Smartphone,
  Work,
} from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { ResourceEditor } from "admin/components/form/ResourceForm";
import StatusTogglerButton from "admin/components/general/buttons/StatusTogglerButton";
import ActiveField from "admin/components/general/fields/ActiveField";
import CardField from "admin/components/general/fields/CardField";
import InlineField from "admin/components/general/fields/InlineField";
import CollaboratorCustomFieldList from "admin/components/resources/collaborator/show/customFields/CollaboratorCustomFieldList";
import { memo, useCallback, useState } from "react";
import {
  DateField,
  RecordContextProvider,
  TextField,
  useRecordContext,
} from "react-admin";

const InfoCard = (props) => {
  const { editable, inFolder } = props;
  const record = useRecordContext();

  const [showEdit, setShowEdit] = useState(false);

  const handleShowEditClose = useCallback(() => {
    setShowEdit(false);
  }, [setShowEdit]);

  const handleShowEditOpen = useCallback(() => {
    setShowEdit(true);
  }, [setShowEdit]);

  const { street, postcode, city } = useRecordContext();

  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Box>
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item>
                  <TextField
                    sx={{ fontWeight: "bold", fontSize: 24 }}
                    source={"firstname"}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    sx={{ fontWeight: "bold", fontSize: 24 }}
                    source={"lastname"}
                  />
                </Grid>

                {editable && (
                  <>
                    <Grid item>
                      <RecordContextProvider
                        value={{
                          ...record,
                          company: record.company["@id"],
                        }}
                      >
                        <ResourceEditor
                          FormContainerProps={{
                            open: showEdit,
                            maxWidth: "md",
                            fullWidth: true,
                            onClose: handleShowEditClose,
                          }}
                          FormProps={{ handleClose: handleShowEditClose }}
                        />
                      </RecordContextProvider>

                      <TextField
                        sx={{ fontSize: 22 }}
                        source={"company.name"}
                        color={"primary"}
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title={"Editer l'usager"}>
                        <IconButton
                          onClick={handleShowEditOpen}
                          color={"secondary"}
                          disabled={!record.active}
                          size={"small"}
                        >
                          <Edit fontSize={"small"} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant={"overline"}>
                    {record.active ? "Créé le " : "Désactivé le"}
                  </Typography>
                </Grid>
                <Grid item>
                  <DateField
                    variant={"overline"}
                    source={record.active ? "createdAt" : "disabledAt"}
                  />
                </Grid>
                <Grid item>
                  <ActiveField
                    source={"active"}
                    text={{
                      enabled: "Activé",
                      disabled: "Désactivé",
                    }}
                  />
                </Grid>
                <Grid item>
                  {editable && (
                    <StatusTogglerButton resource={"collaborators"} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <InlineField source={"jobTitle"} icon={Work} label={""} />
          </Grid>
          <Grid item>
            <InlineField source={"age"} icon={Cake} label={"ans"} />
          </Grid>
          <Grid item>
            <InlineField
              source={"dateOfBirth"}
              type={"date"}
              textDirection={"row-reverse"}
            />
          </Grid>
          <Grid item>
            <InlineField
              source={"isSingle"}
              type={"boolean"}
              additionalProps={{
                text: {
                  true: "Célibataire",
                  false: "En couple",
                },
                textVariant: "overline",
              }}
              icon={House}
            />
          </Grid>
          <Grid item>
            <InlineField
              source={"underagedChildren"}
              type={"text"}
              label={"Enfants à charge"}
              icon={ChildCare}
            />
          </Grid>
        </Grid>
      </Grid>
      {(street || postcode || city) && (
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <PinDrop />
            </Grid>
            <Grid item>
              <TextField variant={"subtitle1"} source={"street"} />
            </Grid>
            <Grid item>
              <TextField variant={"subtitle1"} source={"postcode"} /> -{" "}
              <TextField variant={"subtitle1"} source={"city"} />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <Box mt={inFolder ? 2 : 0}>
          <Grid
            container
            direction={inFolder ? "column" : "row"}
            justifyContent={"flex-start"}
            alignItems={inFolder ? "stretch" : "flex-start"}
          >
            <Grid item>
              <CardField
                source={"email"}
                label={"Adresse e-mail"}
                icon={Email}
              />
            </Grid>
            <Grid item>
              <CardField
                source={"phone"}
                label={"Téléphone fixe"}
                icon={Phone}
              />
            </Grid>
            <Grid item>
              <CardField
                source={"mobilePhone"}
                label={"Téléphone Portable"}
                icon={Smartphone}
              />
            </Grid>
            <CollaboratorCustomFieldList />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(InfoCard);
