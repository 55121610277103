import { Typography } from "@mui/material";
import moment from "moment";
import { useRecordContext } from "react-admin";

const DurationField = ({ source }) => {
  const record = useRecordContext();
  if (!record[source]) {
    return <></>;
  }

  return <Typography>{moment.utc(record[source]).format("HH:mm")}</Typography>;
};

export default DurationField;
