import { Box, DialogContentText, Grid } from "@mui/material";
import { requiredValidation } from "admin/const/validation";
import { useEffect } from "react";
import { TextInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = ["street", "postcode", "city", "name"];

const CompanyStepOne = ({ activeStep, currentStep, goToNextStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();

  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });
      if (canGoNext) goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <Box mb={1}>
        <TextInput
          size={"large"}
          name={"name"}
          source={"name"}
          label={"Nom du client"}
          fullWidth
          validate={requiredValidation}
        />
      </Box>
      <DialogContentText variant={"overline"} align={"center"}>
        Adresse
      </DialogContentText>
      <Box mb={1}>
        <TextInput
          size={"large"}
          validate={requiredValidation}
          fullWidth
          name={"street"}
          source={"street"}
          label={"Numéro et nom de voie"}
        />
      </Box>
      <Box mb={1}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <TextInput
              size={"large"}
              validate={requiredValidation}
              name={"postcode"}
              source={"postcode"}
              label={"Code Postal"}
            />
          </Grid>
          <Grid item md={8}>
            <TextInput
              size={"large"}
              validate={requiredValidation}
              fullWidth
              name={"city"}
              source={"city"}
              label={"Ville"}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CompanyStepOne;
