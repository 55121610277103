import { entrypoint } from "admin/config/dataProvider";

export const getApiAbsoluteRoute = (path) => {
  return `${entrypoint}/${path.substring(1)}`;
};

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

const getInitials = (name) => {
  let initials = name.split(" ");
  if (initials.length > 1) {
    initials = initials.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 2);
  }

  return initials.toUpperCase();
};

export function hexToRgbA(hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.75)"
    );
  }
  throw new Error("Bad Hex");
}

export function stringAvatar(name, sx) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      ...sx,
    },
    children: getInitials(name),
  };
}
