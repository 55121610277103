import { Save } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import DeleteButton from "admin/components/general/buttons/DeleteButton";
import ReferenceCreatorInput from "admin/components/general/input/ReferenceCreatorInput/ReferenceCreatorInput";
import React from "react";
import {
  EditBase,
  NumberInput,
  RecordContextProvider,
  SaveButton,
  SimpleForm,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useUpdate,
} from "react-admin";

const BudgetItemFormContent = ({ isLoading, typeResource }) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
    <Grid container alignItems={"center"} justifyContent={"space-between"}>
      <Grid item sm={3}>
        <NumberInput
          helperText={false}
          name={"amount"}
          size={"large"}
          variant={"outlined"}
          source={"amount"}
          disabled={isLoading}
          label={"Montant"}
        />
      </Grid>
      <Grid item>
        <ReferenceCreatorInput
          ReferenceInputProps={{
            inputProps: { variant: "outlined" },
            variant: "outlined",
            format: (record) => record.name,
            reference: typeResource,
            disabled: isLoading,
            source: "type",
            label: "Catégorie",
          }}
          AutoCompleteInputProps={{
            name: "type",
            helperText: false,
            size: "large",
            variant: "outlined",
            filterToQuery: (searchText) => ({ name: searchText }),
          }}
          DialogInputProps={{
            title: `Créer une catégorie de ${
              typeResource === "income_types" ? "revenus" : "charges"
            }`,
          }}
        />
      </Grid>
      <Grid item>
        <Grid container alignItems={"center"}>
          <Grid item>
            <SaveButton icon={<Save />} variant={"text"} label={""} />
          </Grid>
          <Grid item>
            <DeleteButton record={record} resource={resource} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BudgetItemForm = ({ record, typeResource, index }) => {
  const resource = useResourceContext();
  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const isOdd = index % 2 === 0;
  const onSubmit = (data) => {
    update(
      resource,
      { id: record["@id"], data },
      {
        onSuccess: () => {
          notify("Élément mis à jour", { type: "success" });
        },
      }
    ).then((r) => refresh());
  };

  return (
    <Box p={2} pt={3} sx={{ backgroundColor: isOdd ? grey[100] : "#fff" }}>
      <RecordContextProvider value={record}>
        <EditBase
          component={React.Fragment}
          resource={resource}
          redirect={false}
          mutationMode={"optimistic"}
        >
          <SimpleForm
            component={"div"}
            toolbar={false}
            resource={resource}
            record={record}
            id={`edit_${record["@id"]}`}
          >
            <BudgetItemFormContent
              isLoading={isLoading}
              typeResource={typeResource}
              onSubmit={onSubmit}
            />
          </SimpleForm>
        </EditBase>
      </RecordContextProvider>
    </Box>
  );
};

export default BudgetItemForm;
