import { Dialog } from "@mui/material";
import { CollaboratorFormContainer } from "admin/components/resources/collaborator/form/CollaboratorFormContainer";
import { CompanyFormContainer } from "admin/components/resources/companies/form";
import { DashboardAccessFormContainer } from "admin/components/resources/dashboardAccess/form/DashboardAccessFormContainer";
import { DepartmentFormContainer } from "admin/components/resources/departments/form/DepartmentFormContainer";
import { InterFormContainer } from "admin/components/resources/interventions/form";
import { ReminderFormContainer } from "admin/components/resources/reminders/form";
import { TeamFormContainer } from "admin/components/resources/teams/form/TeamFormContainer";

const resources = [
  {
    name: "collaborators",
    label: "Collaborateur",
    form: {
      container: Dialog,
      component: CollaboratorFormContainer,
    },
  },
  {
    name: "companies",
    label: "Client",
    form: {
      container: Dialog,
      component: CompanyFormContainer,
    },
  },
  {
    name: "reminders",
    label: "Rappel",
    form: {
      container: Dialog,
      component: ReminderFormContainer,
    },
  },
  {
    name: "interventions",
    label: "Intervention",
    form: {
      container: Dialog,
      component: InterFormContainer,
    },
  },
  {
    name: "departments",
    label: "Service",
    form: {
      container: Dialog,
      component: DepartmentFormContainer,
    },
  },
  {
    name: "teams",
    label: "Equipe",
    form: {
      container: Dialog,
      component: TeamFormContainer,
    },
  },
  {
    name: "dashboard_accesses",
    label: "Accès tableau de bord",
    form: {
      container: Dialog,
      component: DashboardAccessFormContainer,
    },
  },
];

export const getResourceConfig = (resourceName) => {
  return resources.find((resource) => resource.name === resourceName);
};
