import { LoadingButton } from "@mui/lab";
import { Divider, Grow } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { entrypoint, httpClient } from "admin/config/dataProvider";
import { emailValidation } from "admin/const/validation";
import { useState } from "react";
import { SimpleForm, TextInput } from "react-admin";
import { Link } from "react-router-dom";

const ResetPasswordSection = () => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = (data) => {
    setLoading(true);
    httpClient(
      `${entrypoint}/security/password/request`,
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      true
    )
      .then(({ json }) => {
        setLoading(false);
        setSent(true);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Box mb={5}>
      <Box p={4}>
        <Grow
          in={!sent}
          mountOnEnter
          unmountOnExit
          onExited={() => setShowConfirmation(true)}
        >
          <div>
            <Typography
              gutterBottom
              align={"center"}
              variant={"h5"}
              fontWeight={"bolder"}
            >
              Mot de passe oublié ? 😳
            </Typography>
            <Typography align={"center"}>
              Pas de panique, on va y remédier !
            </Typography>
            <SimpleForm
              id={"reset_password"}
              onSubmit={handleSubmit}
              toolbar={false}
            >
              <TextInput
                alidate={emailValidation}
                name={"email"}
                source={"email"}
                size={"large"}
                fullWidth
                autoFocus
                label={"Mon e-mail"}
              />
              <LoadingButton
                type="submit"
                fullWidth
                size={"large"}
                loading={loading}
                variant={"outlined"}
              >
                Réinitialiser mon mot de passe
              </LoadingButton>
            </SimpleForm>
            <Box mb={2}>
              <Divider />
            </Box>
          </div>
        </Grow>
        <Grow in={showConfirmation} mountOnEnter unmountOnExit>
          <div>
            <Typography
              gutterBottom
              align={"center"}
              variant={"h5"}
              fontWeight={"bolder"}
            >
              ✉️ Ouvrez votre boite e-mail
            </Typography>
            <Typography align={"center"}>
              Vous allez pouvoir réinitialiser votre mot de passe ici !
            </Typography>
          </div>
        </Grow>
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Button disabled={loading} component={Link} to={"/login"}>
              Revenir à la connexion
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ResetPasswordSection;
