import { Edit } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import StatusTogglerButton from "admin/components/general/buttons/StatusTogglerButton";
import TeamEditor from "admin/components/resources/teams/form/TeamEditor";
import { useCallback, useState } from "react";
import { TextField, useRecordContext, useResourceContext } from "react-admin";

const TeamContainer = () => {
  const resource = useResourceContext();
  const [showEdit, setShowEdit] = useState(false);

  const handleShowEditClose = useCallback(() => {
    setShowEdit(false);
  }, [setShowEdit]);

  const handleShowEditOpen = useCallback(() => {
    setShowEdit(true);
  }, [setShowEdit]);

  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  return (
    <>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h6"} fontWeight={"lighter"}>
            Equipe : &nbsp;
          </Typography>
        </Grid>
        <Grid item>
          <TextField variant={"h6"} fontWeight={"bolder"} source={"name"} />
        </Grid>
        <Grid item>
          <Tooltip title={"Editer l'équipe"}>
            <IconButton
              onClick={handleShowEditOpen}
              color={"secondary"}
              disabled={!record.active}
              size={"small"}
            >
              <Edit fontSize={"small"} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <StatusTogglerButton
            disabled={record.size > 0}
            disabledMessage={"L'équipe doit être vide pour être désactivée"}
          />
        </Grid>
      </Grid>
      <TeamEditor open={showEdit} handleClose={handleShowEditClose} />
      {!record.active && (
        <Box mb={1}>
          <Alert severity={"error"}>
            <AlertTitle>Cette équipe est désactivée</AlertTitle>
            Aucune action n'est possible sur cette équipe.
          </Alert>
        </Box>
      )}
    </>
  );
};

export default TeamContainer;
