import { CheckCircleOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import StepperForm from "admin/components/form/StepperForm";
import CompanyStepOne from "admin/components/resources/companies/form/steps/CompanyStepOne";
import CompanyStepThree from "admin/components/resources/companies/form/steps/CompanyStepThree";
import CompanyStepTwo from "admin/components/resources/companies/form/steps/CompanyStepTwo";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

const formSteps = [
  {
    title: "Informations générales",
    stepComponent: CompanyStepOne,
    add: true,
    edit: true,
  },
  {
    title: "Infos complémentaires",
    stepComponent: CompanyStepTwo,
    add: true,
    edit: true,
  },
  {
    title: "Contrat",
    stepComponent: CompanyStepThree,
    add: true,
  },
];

const CreatedCompanyContent = ({ handleClose }) => {
  const { reset } = useFormContext();

  const handleCloseCallback = useCallback(() => {
    reset();
    handleClose();
  }, [reset, handleClose]);
  return (
    <>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item>
          <Box mb={2} color={"success.light"}>
            <CheckCircleOutlined color={"inherit"} sx={{ fontSize: 75 }} />
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2}>
            <Typography fontWeight={"bolder"} gutterBottom>
              Client enregistré !
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button onClick={handleCloseCallback} variant={"text"} size={"small"}>
            Fermer
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const CompanyFormContainer = ({ formId, handleClose }) => {
  return (
    <Box p={2}>
      <StepperForm
        formId={formId}
        formSteps={formSteps}
        formTitle={"Nouveau client"}
        afterSuccessContent={
          <CreatedCompanyContent handleClose={handleClose} />
        }
      />
    </Box>
  );
};
