import { Box, DialogContentText, Grid } from "@mui/material";
import { useEffect } from "react";
import { NumberInput, SelectInput, TextInput } from "react-admin";

const CollaboratorStepFour = ({ activeStep, currentStep, goToNextStep }) => {
  useEffect(() => {
    if (activeStep === currentStep) {
      goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep]);
  return (
    <>
      <Box mb={1}>
        <SelectInput
          defaultValue={true}
          fullWidth
          helperText={false}
          source={"isSingle"}
          label={"Statut marital"}
          size={"large"}
          choices={[
            { id: true, name: "Célibataire" },
            { id: false, name: "En couple" },
          ]}
          emptyText={"Non spécifié"}
        />
      </Box>

      <NumberInput
        fullWidth
        helperText={"Enfants de moins de 18 ans"}
        defaultValue={0}
        name={"underagedChildren"}
        size={"large"}
        source={"underagedChildren"}
        label={"Enfants à charge"}
      />

      <DialogContentText align={"center"} variant={"overline"}>
        Complémentaire
      </DialogContentText>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextInput
            fullWidth
            source={"insurance"}
            name={"insurance"}
            size={"large"}
            label={"Nom de la complémentaire"}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            fullWidth
            name={"insuranceNumber"}
            source={"insuranceNumber"}
            size={"large"}
            label={"Numéro d'adhérent"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CollaboratorStepFour;
