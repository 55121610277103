import { Add } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid } from "@mui/material";
import {
  DialogAddThematicType,
  DialogEditThematicType,
} from "admin/components/resources/field_management/thematics/form";

import { useCallback, useState } from "react";
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  TextField,
  useRecordContext,
} from "react-admin";

const ListActions = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Grid container direction={"row-reverse"}>
      <Grid item>
        <Button color={"primary"} onClick={handleOpen} startIcon={<Add />}>
          Nouveau type de Thematique
        </Button>
        <DialogAddThematicType open={open} handleClose={handleClose} />
      </Grid>
    </Grid>
  );
};

const EditThematics = () => {
  const record = useRecordContext();

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Grid container direction={"row-reverse"}>
      <Grid item>
        <Button
          color={"primary"}
          onClick={handleOpen}
          startIcon={<EditIcon />}
        ></Button>
        <DialogEditThematicType
          open={open}
          id={`edit_${record["@id"]}`}
          handleClose={handleClose}
          record={record}
        />
      </Grid>
    </Grid>
  );
};

const ThematicsList = () => (
  <List
    hasShow={false}
    hasEdit={false}
    actions={<ListActions />}
    title={"Liste des rappels"}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source={"label"} label={"Label"} />
      <BooleanField source={"active"} label="Actif" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ThematicsList;
