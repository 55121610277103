import { Box, DialogContentText, Grid, ListItemText } from "@mui/material";
import ButtonGroupSelectInput from "admin/components/general/input/CustomReactAdminInput/ButtonGroupSelectInput";
import InterventionLabelForm from "admin/components/general/input/ReferenceCreatorInput/forms/InterventionLabelForm";
import ReferenceCreatorInput from "admin/components/general/input/ReferenceCreatorInput/ReferenceCreatorInput";
import { requiredValidation } from "admin/const/validation";
import { useEffect } from "react";
import { BooleanInput, TextInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = ["type", "report"];

const InterStepTwo = ({ activeStep, goToNextStep, currentStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();
  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || item.error) {
          canGoNext = false;
        }
      });
      if (canGoNext) goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Précisez l'intervention
      </DialogContentText>
      <Box mb={1}>
        <ReferenceCreatorInput
          reference={"intervention_types"}
          fullwidth
          ReferenceInputProps={{
            reference: "intervention_types",
            source: "type",
            label: "Libellé",
            filter: { pagination: false },
          }}
          AutoCompleteInputProps={{
            validate: requiredValidation,
            name: "type",
            label: "Libellé de l'intervention",
            size: "small",
            filterToQuery: (searchText) => ({ code: searchText }),
            optionText: (record) => (
              <ListItemText primary={record.code} secondary={record.label} />
            ),
            inputText: (record) => record.label,
          }}
          DialogInputProps={{
            title: "Créer un libellé",
            form: InterventionLabelForm,
          }}
        />
      </Box>
      <Box mb={1}>
        <TextInput
          validate={requiredValidation}
          name={"report"}
          fullWidth
          source={"report"}
          label={"Description de l'intervention"}
          multiline
        />
      </Box>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <ButtonGroupSelectInput
            name={"physical"}
            choices={[
              { label: "Présentiel", value: true },
              {
                label: "Distanciel",
                value: false,
              },
            ]}
          />
        </Grid>
        <Grid item>
          <BooleanInput
            name={"isAppointment"}
            source="isAppointment"
            label={"Cette intervention est un rendez-vous"}
            options={{}}
            helperText={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InterStepTwo;
