import { useRecordContext } from "react-admin";

const ConditionColoredField = ({
  component: FieldComponent,
  source,
  condition,
  colors = { true: "inherit", false: "error" },
  ...fieldProps
}) => {
  const record = useRecordContext();
  const conditionalColor = colors[!condition(record)];

  return (
    <FieldComponent color={conditionalColor} source={source} {...fieldProps} />
  );
};

export default ConditionColoredField;
