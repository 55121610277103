import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseDialogButton from "admin/components/general/ui/dialog/CloseDialogButton";
import { firstnameValidation } from "admin/const/validation";
import {
  AutocompleteInput,
  ReferenceInput,
  SaveButton,
  TextInput,
  useRecordContext,
} from "react-admin";

// const CreatedInterContent = ({ handleClose }) => {
//   const { reset } = useFormContext();
//
//   const handleCloseCallback = useCallback(() => {
//     reset();
//     handleClose();
//   }, [reset, handleClose]);
//   return (
//     <>
//       <Grid container direction={"column"} alignItems={"center"}>
//         <Grid item>
//           <Box mb={2} color={"success.light"}>
//             <CheckCircleOutlined color={"inherit"} sx={{ fontSize: 75 }} />
//           </Box>
//         </Grid>
//         <Grid item>
//           <Box mb={2}>
//             <Typography fontWeight={"bolder"} gutterBottom>
//               Intervention enregistrée !
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid item>
//           <Button onClick={handleCloseCallback} variant={"text"} size={"small"}>
//             Fermer
//           </Button>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

export const TeamFormContainer = ({ formId, handleClose, department }) => {
  const record = useRecordContext();
  return (
    <Box p={2}>
      <CloseDialogButton handleClose={handleClose} />
      <DialogTitle align={"center"} fontWeight={"bolder"}>
        {record
          ? `Éditer l'équipe ${record.name}`
          : "Créer une nouvelle équipe"}
      </DialogTitle>
      {!record && (
        <Alert severity={"info"}>
          Cette équipe sera rattachée au service "{department.name}"
        </Alert>
      )}
      <DialogContent>
        <Box mt={1}>
          <TextInput
            validate={firstnameValidation}
            name={"name"}
            source={"label"}
            size={"large"}
            fullWidth
            label={"Nom de l'équipe"}
          />
        </Box>
        {record && (
          <ReferenceInput
            source={"department"}
            reference={"departments"}
            filter={{ pagination: false, company: record.company }}
          >
            <AutocompleteInput
              size={"large"}
              label={"Service"}
              optionValue="id"
              fullWidth
              optionText="name"
            />
          </ReferenceInput>
        )}
      </DialogContent>
      <DialogActions>
        <SaveButton
          type={"button"}
          form={formId}
          label={record ? "Mettre à jour" : "Créer l'équipe"}
          variant={"text"}
        ></SaveButton>
      </DialogActions>
    </Box>
  );
};
