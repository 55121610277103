import {
  AppBar as MuiAppBar,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/styles";
import { drawerWidth } from "admin/layout/AppDrawer";
import LoggedUserCard from "admin/layout/LoggedUserCard";
import logo from "assets/img/logo.png";
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  padding: theme.spacing(2, 3),
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
}));

const CustomAppBar = ({ children }) => {
  const theme = useTheme();
  const verticalSpacing = theme.spacing(5);
  return (
    <AppBar
      position={"static"}
      color={"secondary"}
      sx={{
        marginTop: `-${verticalSpacing}`,
        marginLeft: "0 !important",
        width: "100% !important",
      }}
    >
      <Container maxWidth={"xl"}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item>
                <img src={logo} alt="" width={100} />
              </Grid>
              <Grid item>
                <Typography variant={"h6"}>Mon service social</Typography>
                <Typography>Votre tableau de bord</Typography>
              </Grid>
            </Grid>

            <Box id={"react-admin-title"} />
          </Grid>
          <Grid item>
            <LoggedUserCard />
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default CustomAppBar;
