import { email, maxLength, minLength, regex, required } from "react-admin";

const requiredMessage = "Ce champ est requis";
const tooShortMessage = "est trop court";
const tooLongMessage = "est trop long";

export const requiredValidation = [required(requiredMessage)];

export const firstnameValidation = [
  ...requiredValidation,
  minLength(2, `Le prénom ${tooShortMessage}`),
  maxLength(255, `Le prénom ${tooLongMessage}`),
];

export const lastNameValidation = [
  ...requiredValidation,
  minLength(2, `Le nom ${tooShortMessage}`),
  maxLength(255, `Le nom ${tooLongMessage}`),
];

export const emailValidation = [email("L'e-mail est mal formaté")];

export const phoneValidation = [
  regex(
    /^((\+)33|0|0033)[1-9](\d{2}){4}$/,
    "Le numéro de téléphone est mal formatté"
  ),
];

export const healthCareNumberValidation = [
  ...requiredValidation,
  regex(
    /^[12][0-9]{2}[0-1][0-9](2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}[0-9]{2}$/,
    "Le numéro de sécurité sociale est mal formatté"
  ),
];

export const passwordValidation = [
  ...requiredValidation,
  regex(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})|^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
    "Le mot de passe n'est pas assez fort"
  ),
];

export const strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
);
export const mediumPassword = new RegExp(
  "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
);
