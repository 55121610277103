import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import * as React from "react";

import {
  RecordContextProvider,
  useGetIdentity,
  useListContext,
} from "react-admin";

import moment from "moment";
import { useListFilterContext } from "ra-core"; // a plugin!
import { useCallback, useEffect, useRef, useState } from "react";
import ReminderCreator from "../../reminders/form/ReminderCreator";
import ReminderEditor from "../../reminders/form/ReminderEditor";
import AgendaItem from "./AgendaItem";

function renderEventContent(eventInfo) {
  const {
    event: {
      extendedProps: { reminder },
    },
  } = eventInfo;
  return <AgendaItem reminder={reminder} />;
}

const AgendaIterator = ({ handleOpen }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [defaultSupervisor, setDefaultSupervisor] = useState(false);
  const { identity } = useGetIdentity();
  const { filterValues, setFilters } = useListFilterContext();
  const calendarRef = useRef();
  useEffect(() => {
    if (identity) {
      setDefaultSupervisor(identity["@id"]);
    }
  }, [identity, setDefaultSupervisor]);

  const handleDateChange = useCallback(
    ({ start, end, view: { calendar } }) => {
      if (
        moment(start.toString()).toString() !==
        moment(filterValues["sendAt[after]"]).toString()
      ) {
        setTimeout(() => {
          setFilters({
            "collaborator.company": filterValues["collaborator.company"],
            "sendAt[after]": start,
            "sendAt[before]": end,
          });
        }, 300);
      }
    },
    [setFilters, filterValues]
  );

  const handleShowEditOpen = useCallback(
    ({
      event: {
        extendedProps: { reminder },
      },
    }) => {
      if (!reminder.done) {
        setEditRecord(reminder);
        setShowEdit(true);
      }
    },
    [setShowEdit]
  );

  const handleFormOpen = useCallback(() => {
    setFormOpen(true);
  }, [setFormOpen]);

  const handleFormClose = useCallback(() => {
    setFormOpen(false);
    setInitialValues(null);
  }, [setFormOpen]);

  const handleDateClick = useCallback(({ date }) => {
    setInitialValues({
      user: defaultSupervisor,
      sendAt: date,
    });
    handleFormOpen();
  });

  const { data } = useListContext();
  return (
    <>
      <FullCalendar
        timeZone={"UTC"}
        locales={[frLocale]}
        locale={"fr"}
        selectable
        ref={calendarRef}
        customButtons={{
          filterButton: {
            text: "Filtrer par client",
            click: handleOpen,
          },
        }}
        headerToolbar={{
          left: "filterButton",
          center: "title",
          right: "prev,next",
        }}
        dateClick={handleDateClick}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        weekends={false}
        datesSet={handleDateChange}
        eventClick={handleShowEditOpen}
        eventContent={renderEventContent}
        events={
          data
            ? data.map((reminder) => {
                return {
                  title: reminder.type.label,
                  date: reminder.sendAt,
                  allDay: true,
                  backgroundColor: "rgba(0,0,0,0)",
                  textColor: "#000",
                  borderColor: "rgba(0,0,0,0)",
                  display: "block",
                  extendedProps: {
                    reminder,
                  },
                };
              })
            : []
        }
      />
      <RecordContextProvider value={editRecord}>
        <ReminderEditor showEdit={showEdit} setShowEdit={setShowEdit} />
      </RecordContextProvider>

      {initialValues && (
        <ReminderCreator
          open={formOpen}
          onClose={handleFormClose}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

export default React.memo(AgendaIterator);
