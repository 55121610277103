import { ResourceCreator } from "admin/components/form/ResourceForm";
import { ResourceContextProvider, useRecordContext } from "react-admin";

const TeamCreator = ({ open, onClose, initialValues }) => {
  const department = useRecordContext();
  const transform = (data) => ({
    ...data,
    department: department["@id"],
  });

  return (
    <ResourceContextProvider value={"teams"}>
      <ResourceCreator
        FormContainerProps={{ open, maxWidth: "xs", fullWidth: true, onClose }}
        FormProps={{ handleClose: onClose, department }}
        transform={transform}
      />
    </ResourceContextProvider>
  );
};

export default TeamCreator;
