import { ResourceCreator } from "admin/components/form/ResourceForm";
import { useCallback } from "react";
import { ResourceContextProvider, useRecordContext } from "react-admin";

const DepartmentCreator = ({ open, onClose, initialValues }) => {
  const { "@type": type, ...record } = useRecordContext();
  const transform = useCallback(
    (data) => {
      switch (type) {
        case "Department":
          return {
            ...data,
            company: record["company"],
            parent: record["@id"],
          };
        case "Company":
          return {
            ...data,
            company: record["@id"],
          };
        default:
          return data;
      }
    },
    [type]
  );
  return (
    <ResourceContextProvider value={"departments"}>
      <ResourceCreator
        FormContainerProps={{ open, maxWidth: "xs", fullWidth: true, onClose }}
        FormProps={{ handleClose: onClose }}
        initialValues={initialValues}
        transform={transform}
      />
    </ResourceContextProvider>
  );
};

export default DepartmentCreator;
