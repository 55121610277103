import { ResourceCreator } from "admin/components/form/ResourceForm";
import {
  ResourceContextProvider,
  useRecordContext,
  useRefresh,
} from "react-admin";

const DashboardAccessCreator = ({ open, handleClose, initialValues }) => {
  const company = useRecordContext();
  const refresh = useRefresh();
  const transform = (data) => ({
    ...data,
    company: company["@id"],
  });

  const baseComponentProps = {
    mutationOptions: {
      onSuccess: () => {
        handleClose();
        refresh();
      },
    },
  };

  return (
    <ResourceContextProvider value={"dashboard_accesses"}>
      <ResourceCreator
        BaseComponentProps={baseComponentProps}
        FormContainerProps={{
          open,
          maxWidth: "xs",
          fullWidth: true,
          onClose: handleClose,
        }}
        FormProps={{ handleClose: handleClose, company }}
        transform={transform}
      />
    </ResourceContextProvider>
  );
};

export default DashboardAccessCreator;
