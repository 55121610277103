import FormTypeContext from "admin/components/general/context/FormTypeContext";
import { getResourceConfig } from "admin/const/resources";
import React from "react";
import {
  CreateBase,
  EditBase,
  SimpleForm,
  useCreateContext,
  useEditContext,
  useRecordContext,
  useResourceContext,
} from "react-admin";

const ResourceForm = ({
  BaseComponent,
  resource: overrideResource,
  BaseComponentProps = { mutationOptions: {} },
  FormContainerProps = {},
  FormProps,
  getContext,
  redirect = false,
  initialValues = {},
  withRecord,
  formType,
  ...props
}) => {
  const record = useRecordContext();
  const resource = useResourceContext() || overrideResource;
  const {
    form: { component: Component, container: FormContainer = React.Fragment },
  } = getResourceConfig(resource);
  return (
    <FormTypeContext.Provider value={formType}>
      <BaseComponent
        component={React.Fragment}
        resource={resource}
        redirect={false}
        mutationMode={"optimistic"}
        {...BaseComponentProps}
      >
        <SimpleForm
          component={"div"}
          mode={"onBlur"}
          reValidateMode={"onChange"}
          toolbar={false}
          defaultValues={initialValues}
          record={withRecord ? record : null}
          {...props}
        >
          <FormContainer {...FormContainerProps}>
            <Component
              {...FormProps}
              formId={props.id}
              getContext={getContext}
              formType={formType}
            />
          </FormContainer>
        </SimpleForm>
      </BaseComponent>
    </FormTypeContext.Provider>
  );
};

export const ResourceCreator = ({
  BaseComponentProps = {},
  transform,
  ...props
}) => {
  const resource = useResourceContext();
  return (
    <ResourceForm
      BaseComponent={CreateBase}
      BaseComponentProps={{ ...BaseComponentProps, transform }}
      {...props}
      formType={"add"}
      id={`${resource}_create_form`}
      getContext={useCreateContext}
      withRecord={false}
    />
  );
};

export const ResourceEditor = ({
  BaseComponentProps = {},
  overrideRecord,
  ...props
}) => {
  const record = useRecordContext();
  return (
    <ResourceForm
      BaseComponent={EditBase}
      BaseComponentProps={{ ...BaseComponentProps, id: record["@id"] }}
      {...props}
      formType={"edit"}
      id={`${record["@id"]}_edit_form`}
      getContext={useEditContext}
      withRecord
    />
  );
};
