import { InfoOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import CardField from "admin/components/general/fields/CardField";
import {
  RecordContextProvider,
  ReferenceManyField,
  useListContext,
} from "react-admin";

const CollaboratorCustomFieldIterator = () => {
  const { data: customFields, isLoading } = useListContext();
  return (
    <>
      {!isLoading &&
        customFields.map((customField) => (
          <Grid item key={`collaborator_custom_field_${customField["@id"]}`}>
            <RecordContextProvider value={customField}>
              <CardField
                source={"value"}
                label={customField.title}
                icon={InfoOutlined}
              />
            </RecordContextProvider>
          </Grid>
        ))}
    </>
  );
};

const CollaboratorCustomFieldList = () => {
  return (
    <>
      <ReferenceManyField
        reference={"collaborator_custom_fields"}
        target={"collaborator"}
      >
        <CollaboratorCustomFieldIterator />
      </ReferenceManyField>
    </>
  );
};

export default CollaboratorCustomFieldList;
