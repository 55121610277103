import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import { Box, DialogContentText, Grid } from "@mui/material";
import { emailValidation, requiredValidation } from "admin/const/validation";
import { useEffect } from "react";
import { TextInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = [];

const CompanyStepTwo = ({ activeStep, goToNextStep, currentStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();
  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });
      if (canGoNext) goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Qui est le contact référent sur site ?
      </DialogContentText>
      <Box mt={2}>
        <TextInput
          size={"large"}
          name={"contactName"}
          source={"contactName"}
          label={"Nom du contact"}
          fullWidth
          validate={requiredValidation}
        />
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextInput
              InputProps={{
                startAdornment: (
                  <EmailOutlined sx={{ pr: 2 }} color={"disabled"} />
                ),
                placeholder: "contact@client.fr",
              }}
              size={"large"}
              name={"contactEmail"}
              source={"contactEmail"}
              label={"Email du contact"}
              validate={emailValidation}
              fullWidth
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              InputProps={{
                startAdornment: (
                  <PhoneOutlined sx={{ pr: 2 }} color={"disabled"} />
                ),
                placeholder: "02 XX XX XX XX",
              }}
              size={"large"}
              name={"contactPhone"}
              source={"contactPhone"}
              label={"Téléphone du contact"}
              fullWidth
            />
          </Grid>
            <Grid item xs={12}>
                <TextInput
                    multiline
                    size={"large"}
                    rows={5}
                    fullWidth
                    name={"notes"}
                    source={"notes"}
                    label={"Notes internes"}
                />
            </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CompanyStepTwo;
