import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import DepartmentItem from "admin/components/resources/departments/DepartmentItem";
import { RecordContextProvider, useListContext } from "react-admin";

const DepartmentIterator = (props) => {
  const { data } = useListContext();

  return (
    <List
      sx={{ width: "100%" }}
      component="nav"
      subheader={
        <ListSubheader component={"div"} id={"treeview-title"}>
          Arborescence
        </ListSubheader>
      }
    >
      <ListItemButton dense onClick={() => props.handleSelected(null)}>
        <ListItemText primary={"Collaborateurs sans équipe"} />
      </ListItemButton>
      {data &&
        data.map((department) => {
          return (
            <RecordContextProvider value={department} key={department["@id"]}>
              <DepartmentItem {...props} />
            </RecordContextProvider>
          );
        })}
    </List>
  );
};

export default DepartmentIterator;
