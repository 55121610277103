import { Email, Phone } from "@mui/icons-material";
import { Alert, Card, CardActions, CardContent, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import { memo } from "react";
import { RecordContextProvider } from "react-admin";
import { priorityArray } from "../../../../const/priority";
import UpdateReminderStatusButton from "../../../general/buttons/UpdateReminderStatusButton";
import TransparentTooltip from "../../../general/ui/TransparentTooltip";

const AgendaItem = ({ reminder }) => {
  const { collaborator, type, done, priority, phone, email } = reminder;
  const sendAt = moment(reminder.sendAt);

  return (
    <RecordContextProvider value={reminder}>
      <TransparentTooltip
        sx={{ backgroundColor: "rgba(0,0,0,0)" }}
        title={
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {collaborator.fullName}
              </Typography>
              {(phone || email) && (
                <Grid container>
                  {phone && (
                    <Grid item>
                      <Grid>
                        <Grid item>
                          <Phone />
                        </Grid>
                        <Grid>{phone}</Grid>
                      </Grid>
                    </Grid>
                  )}
                  {email && (
                    <Grid item>
                      <Grid>
                        <Grid item>
                          <Email />
                        </Grid>
                        <Grid>{email}</Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
              <Typography variant="h6" component="div">
                {type.label}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {sendAt.format("L")}
              </Typography>
              <Typography variant="body2">{reminder.description}</Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <UpdateReminderStatusButton
                variant={"text"}
                label={"Statut"}
                source={"done"}
              />
            </CardActions>
          </Card>
        }
      >
        <Alert
          severity={!done ? priorityArray[priority]["severity"] : "success"}
          variant={"filled"}
          sx={{
            cursor: "pointer",
            overflow: "hidden",
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 0,
            paddingLeft: 1,
          }}
        >
          <Typography
            component={"p"}
            gutterBottom={false}
            variant={"caption"}
            sx={{
              lineHeight: 1,
              textOverflow: "ellipsis",
              overflow: "hidden",
              textTransform: "uppercase",
              fontWeight: "bolder",
            }}
          >
            {type.label}
          </Typography>
          <Typography
            variant={"caption"}
            sx={{
              lineHeight: 1,
              textOverflow: "ellipsis",
              overflow: "hidden",
              textTransform: "capitalize",
            }}
          >
            {collaborator.fullName}
          </Typography>
          <Typography
            variant={"overline"}
            component={"p"}
            sx={{
              lineHeight: 1,
              fontSize: 8,
              textOverflow: "ellipsis",
              overflow: "hidden",
              textTransform: "capitalize",
            }}
          >
            {collaborator.company?.name}
          </Typography>
        </Alert>
      </TransparentTooltip>
    </RecordContextProvider>
  );
};

export default memo(AgendaItem);
