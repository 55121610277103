import { Dialog, DialogContent, DialogTitle, Grid, Grow } from "@mui/material";
import DownloadFileField from "admin/components/general/fields/DownloadFileField.js";
import { getApiAbsoluteRoute } from "admin/const/utils";
import { useState } from "react";
import { useRecordContext } from "react-admin";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

const FileView = ({ source, open, handleClose }) => {
  const record = useRecordContext();
  const url = getApiAbsoluteRoute(record[source]);

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  return (
    <Dialog
      TransitionComponent={Grow}
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      maxWidth={"lg"}
      onClose={handleClose}
      open={open}
      onLoadError={console.error}
    >
      <DialogTitle>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>{record.originalName}</Grid>
          <Grid item>
            <DownloadFileField source={"publicUrl"} text />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          error="Impossible de charger le document"
          loading="Chargement..."
          noData="Aucun fichier trouvé"
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              options={options}
            />
          ))}
        </Document>
      </DialogContent>
    </Dialog>
  );
};

export default FileView;
