import { ListItemButton, ListItemText } from "@mui/material";
import React, { useCallback } from "react";
import { useRecordContext } from "react-admin";

const TeamItem = ({ leftPadding, handleSelected }) => {
  const { "@id": IRI, name, size, active } = useRecordContext();
  const levelPadding = leftPadding + 2;

  const handleSelectCallback = useCallback(() => {
    handleSelected(IRI);
  }, [IRI, handleSelected]);

  return (
    <ListItemButton
      dense
      sx={{ pl: levelPadding }}
      onClick={handleSelectCallback}
    >
      <ListItemText primary={name} secondary={`${size} collaborateurs`} />
    </ListItemButton>
  );
};

export default React.memo(TeamItem);
