import {
  EuroOutlined,
  FamilyRestroomOutlined,
  Folder,
  ForestOutlined,
  HealthAndSafetyOutlined,
  HouseOutlined,
  WheelchairPickupOutlined,
} from "@mui/icons-material";

const thematics = [
  {
    label: "Logement",
    icon: HouseOutlined,
    color: "#8a39df",
  },
  {
    label: "Santé",
    icon: HealthAndSafetyOutlined,
    color: "#03a9f4",
  },
  {
    label: "Handicap",
    icon: WheelchairPickupOutlined,
    color: "#dc49ba",
  },
  {
    label: "Budget",
    icon: EuroOutlined,
    color: "#8bc34a",
  },
  {
    label: "Famille",
    icon: FamilyRestroomOutlined,
    color: "#ff5722",
  },
  {
    label: "Retraite",
    icon: ForestOutlined,
    color: "#1ec982",
  },
];

const generateDefaultIcon = (label) => {
  return {
    label,
    icon: Folder,
    color: "#555",
  };
};

export const getThematicConfig = (label) => {
  return (
    thematics.find((thematic) => thematic.label === label) ||
    generateDefaultIcon(label)
  );
};
export default thematics;
