import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import {
  useRecordContext,
  useRefresh,
  useResourceContext,
  useUpdate,
} from "react-admin";

const UpdateReminderStatusButton = ({ children }, ...props) => {
  const refresh = useRefresh();
  const record = useRecordContext();
  const resource = useResourceContext();
  const updateData = { done: !record.done };

  const [update, { isLoading }] = useUpdate(
    resource,
    {
      id: record.id,
      data: updateData,
      previousData: record,
    },
    {
      onSuccess: () => {
        refresh();
      },
    }
  );

  const handleClick = () => {
    update();
  };

  return (
    <LoadingButton
      size={"small"}
      name={`update_button_${record["@id"]}`}
      disabled={isLoading}
      loading={isLoading}
      {...props}
      onClick={handleClick}
      startIcon={record.done ? <CheckCircle /> : <RadioButtonUnchecked />}
    >
      {record.done ? "Réouvrir" : "Clôturer "}
    </LoadingButton>
  );
};

export default UpdateReminderStatusButton;
