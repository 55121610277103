import { Box, Grid } from "@mui/material";
import { requiredValidation } from "admin/const/validation";
import { useEffect } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";

const ThematicInputContainer = () => {
  return (
    <ReferenceInput size={"small"} source={"thematic"} reference={"thematics"}>
      <AutocompleteInput
        size={"small"}
        label={"Thématique"}
        disabled
        optionValue={"id"}
      />
    </ReferenceInput>
  );
};

const InterStepFour = ({ activeStep, currentStep, goToNextStep }) => {
  useEffect(() => {
    if (activeStep === currentStep) {
      goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mb={1}>
            <ReferenceInput
              size={"small"}
              source={"thematic"}
              reference={"thematics"}
            >
              <AutocompleteInput
                size={"small"}
                label={"Thématique"}
                optionValue={"id"}
                validate={requiredValidation}
              />
            </ReferenceInput>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InterStepFour;
