import { Box } from "@mui/material";
import CollaboratorContainer from "admin/components/resources/collaborator/show/CollaboratorContainer";
import PageTitle from "admin/layout/PageTitle";
import { Show } from "react-admin";

const CollaboratorShow = () => {
  return (
    <Show title={<PageTitle>Fiche collaborateur</PageTitle>} component={Box}>
      <CollaboratorContainer />
    </Show>
  );
};

export default CollaboratorShow;
