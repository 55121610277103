import { IconButton, Tooltip } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useRecordContext, useResourceContext, useUpdate } from "react-admin";

const StatusTogglerButton = ({
  disabled = false,
  disabledMessage = "Impossible de modifier le statut",
}) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const diff = { active: !record.active };
  const [update, { isLoading, error }] = useUpdate();

  const handleActivation = () => {
    update(resource, { id: record["@id"], data: diff, previousData: record });
  };

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Tooltip
      title={
        !disabled
          ? !record.active
            ? "Activer"
            : "Désactiver"
          : disabledMessage
      }
    >
      <IconButton
        disabled={disabled || isLoading}
        size={"small"}
        onClick={handleActivation}
      >
        {
          <Switch
            disabled={disabled || isLoading}
            checked={record.active}
            onChange={handleActivation}
          />
        }
      </IconButton>
    </Tooltip>
  );
};

export default StatusTogglerButton;
