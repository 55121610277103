import { ResourceCreator } from "admin/components/form/ResourceForm";
import { ResourceContextProvider } from "react-admin";

const CompanyCreator = ({ open, handleClose }) => {
  const transform = ({
    startDate,
    endDate,
    remoteHours,
    physicalHours,
    ...data
  }) => {
    return {
      ...data,
      contracts: [
        {
          startDate: startDate,
          endDate,
          remoteHours: parseInt(remoteHours),
          physicalHours: parseInt(physicalHours),
        },
      ],
    };
  };
  return (
    <ResourceContextProvider value={"companies"}>
      <ResourceCreator
        transform={transform}
        FormContainerProps={{
          open,
          maxWidth: "md",
          fullWidth: true,
          onClose: handleClose,
        }}
        FormProps={{ handleClose }}
        resource={"companies"}
      />
    </ResourceContextProvider>
  );
};

export default CompanyCreator;
