import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import InterventionEditor from "admin/components/resources/interventions/form/InterventionEditor";
import ReminderEditor from "admin/components/resources/reminders/form/ReminderEditor";
import { useCallback, useState } from "react";
import { useRecordContext } from "react-admin";

const EditButton = ({ intervention, disabled }) => {
  const [showEdit, setShowEdit] = useState(false);
  const record = useRecordContext();

  const handleShowEditOpen = useCallback(() => {
    setShowEdit(true);
  }, [setShowEdit]);

  return (
    <>
      {intervention ? (
        <InterventionEditor
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          record={record}
        />
      ) : (
        <ReminderEditor
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          record={record}
        />
      )}
      <Tooltip
        title={intervention ? "Editer l'intervention" : "Editer le rappel"}
      >
        <IconButton
          onClick={handleShowEditOpen}
          color={"secondary"}
          disabled={disabled}
          size={"small"}
        >
          <Edit fontSize={"small"} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default EditButton;
