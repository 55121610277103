import { Box, Grid, ListItemText } from "@mui/material";
import { useEffect } from "react";
import {
  AutocompleteInput,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = ["collaborator", "author"];

const InterStepThree = ({ activeStep, currentStep, goToNextStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();
  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || item.error) {
          canGoNext = false;
        }
      });
      if (canGoNext) goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mb={1}>
            <ReferenceInput
              size={"small"}
              source={"collaborator"}
              reference={"collaborators"}
            >
              <AutocompleteInput
                size={"small"}
                label={"Collaborateur"}
                optionValue="id"
                optionText={(record) => (
                  <ListItemText
                    secondary={
                      <ReferenceField
                        link={false}
                        record={record}
                        reference={"collaborators"}
                        source={"collaborator"}
                      >
                        <TextField source={"name"} />
                      </ReferenceField>
                    }
                    primary={`${record.firstname} ${record.lastname}`}
                  />
                )}
                inputText={(record) => `${record.firstname} ${record.lastname}`}
              />
            </ReferenceInput>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={1}>
            <ReferenceInput
              size={"small"}
              source={"author"}
              reference={"users"}
            >
              <AutocompleteInput
                size={"small"}
                label={"Auteur"}
                optionValue="id"
                inputText={(record) => `${record.firstname} ${record.lastname}`}
              />
            </ReferenceInput>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InterStepThree;
