import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import { fetchUtils } from "react-admin";

//export const entrypoint = `${window.location.protocol}//api.${window.location.hostname}`;
//export const entrypoint = `http://api.supernanny.localhost`;
export const entrypoint = `https://api.monservicesocial.groupe-neholys.com`;

export const getHeaders = () =>
  localStorage.getItem("token")
    ? {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    : {};

export const httpClient = (url, options = {}, anonymous = false) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/ld+json" });
  }
  if (!anonymous) {
    const token = localStorage.getItem("token");
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  return fetchUtils.fetchJson(url, options);
};

const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });

const apiDocumentationParser = (setRedirectToLogin) => async () => {
  try {
    setRedirectToLogin(false);
    return await parseHydraDocumentation(entrypoint, { headers: getHeaders });
  } catch (result) {
    const { api, response, status } = result;
    if (status !== 401 || !response) {
      throw result;
    }
    // Prevent infinite loop if the token is expired
    localStorage.removeItem("token");
    setRedirectToLogin(true);

    return {
      api,
      response,
      status,
    };
  }
};

const dataProvider = (setRedirectToLogin) => {
  return hydraDataProvider({
    entrypoint,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
    mercure: false,
    useEmbedded: true,
  });
};

const configuredDataProvider = (setRedirectToLogin) => {
  const theDataProvider = dataProvider(setRedirectToLogin);
  return {
    ...theDataProvider,
    create: (resource, params) => {
      if (!params.data.file) {
        // fallback to the default implementation
        return theDataProvider.create(resource, params);
      }

      let formData = new FormData();

      formData.append("file", params.data.file.rawFile);

      return httpClient(`${entrypoint}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    },
  };
};
export default configuredDataProvider;
