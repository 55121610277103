import { EmojiPeople } from "@mui/icons-material";
import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { TextField } from "react-admin";

const CreatedCollaborator = () => {
  const {
    palette: { primary, secondary },
  } = useTheme();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Paper
      variant={"outlined"}
      sx={{
        background: `linear-gradient(45deg, ${primary.light} 30%, ${secondary.light} 90%)`,
      }}
    >
      <Box p={3}>
        <Grid container alignItems={"center"} spacing={1} direction={"column"}>
          <Grid item>
            <TextField
              variant={"h4"}
              component={"span"}
              align={"center"}
              fontWeight={"bolder"}
              color={"white"}
              source={"collaboratorSize"}
            />
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item xs={3}>
                <EmojiPeople
                  sx={{ color: "white", fontSize: "2.5em" }}
                  color={"white"}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography
                  color={"white"}
                  align={"center"}
                  component={"p"}
                  textTransform={"uppercase"}
                  fontWeight={"bolder"}
                >
                  Usagers
                </Typography>
                <Typography
                  gutterBottom={false}
                  color={"white"}
                  textTransform={"uppercase"}
                  align={"center"}
                  fontSize={"small"}
                  fontWeight={"bolder"}
                >
                  actifs
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CreatedCollaborator;
