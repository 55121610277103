import { PowerSettingsNew } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AvatarField from "admin/components/general/fields/AvatarField";
import {
  RecordContextProvider,
  TextField,
  useGetIdentity,
  useLogout,
} from "react-admin";

const LoggedUserCard = () => {
  const { identity } = useGetIdentity();
  const logout = useLogout();

  const handleLogout = () => logout();

  return (
    <RecordContextProvider value={identity}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item>
          <AvatarField size={40} source={"fullName"} />
        </Grid>
        <Grid item>
          <TextField
            fontWeight={"bolder"}
            source={"fullName"}
            component={"p"}
          />
        </Grid>
        <Grid item>
          <Tooltip title={"Se déconnecter"}>
            <IconButton onClick={logout}>
              <PowerSettingsNew />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </RecordContextProvider>
  );
};

export default LoggedUserCard;
