import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
import { requiredValidation } from "../../../../../const/validation";

const ThematicsEdit = () => {
  return (
    <Edit resource={"thematics"}>
      <SimpleForm>
        <TextInput
          validate={requiredValidation}
          placeholder={"Sit corrupti non omnis et."}
          name={"label"}
          fullWidth
          source={"label"}
          label={"Intitulé"}
        />
        <BooleanInput
          name={"active"}
          source={"active"}
          label="Inactif / Actif"
          defaultValue
        />
      </SimpleForm>
    </Edit>
  );
};

export default ThematicsEdit;
