import { Box, Container } from "@mui/material";
import CompanyContainer from "admin/components/resources/companies/show/CompanyContainer";
import TitleSection from "admin/components/resources/companies/show/infos";
import React from "react";
import { Show } from "react-admin";

const CompanyShow = () => {
  return (
    <Show component={Box} title={<TitleSection />}>
      <Container maxWidth={"xl"}>
        <CompanyContainer />
      </Container>
    </Show>
  );
};

export default React.memo(CompanyShow);
