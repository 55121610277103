import { Business, Group, NotificationsActive } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LoginPage from "admin/components/pages/login";
import InitializePasswordSection from "admin/components/pages/login/InitializePasswordSection";
import RequestPasswordSection from "admin/components/pages/login/RequestPasswordSection";
import ResetPasswordSection from "admin/components/pages/login/ResetPasswordSection";
import {
  CompaniesList,
  CompanyShow,
} from "admin/components/resources/companies";
import authProvider from "admin/config/authProvider";
import configuredDataProvider from "admin/config/dataProvider";
import queryClient from "admin/config/queryClient";
import { lightTheme } from "admin/layout/themes.ts";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "moment/locale/fr";
import polyglotI18nProvider from "ra-i18n-polyglot";
import fr from "ra-language-french";
import { useState } from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { BrowserRouter, Navigate, Route } from "react-router-dom";
import {
  CollaboratorList,
  CollaboratorShow,
} from "./admin/components/resources/collaborator";
import DialogEditInterventionType from "./admin/components/resources/field_management/interventions/form";
import InterventionList from "./admin/components/resources/field_management/interventions/list/InterventionsList";
import RemindersList from "./admin/components/resources/field_management/reminders/list/RemindersList";
import ThematicsList from "./admin/components/resources/field_management/thematics/list/ThematicsList";
import UserList from "./admin/components/resources/users/UserList";

import { useIntrospection } from "@api-platform/admin";
import { isAdmin } from "admin/const/permissions";
import AppLayout from "AppLayout";
import ContractDashboard from "dashboard/pages/ContractDashboard";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import AgendaReminderBaseList from "./admin/components/resources/agenda/list/AgendaReminderBaseList";
import ThematicsEdit from "./admin/components/resources/field_management/thematics/list/ThematicsEdit";
import UserCreate from "./admin/components/resources/users/UserCreate";
import UserEdit from "./admin/components/resources/users/UserEdit";

momentDurationFormatSetup(moment);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
const i18nProvider = polyglotI18nProvider(() => fr, "fr");

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("token")) {
    return <></>;
  }
  return <Navigate to="/login" />;
};

const App = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"fr"}>
        <Admin
          queryClient={queryClient}
          i18nProvider={i18nProvider}
          title={"Mon service social"}
          loginPage={LoginPage}
          theme={lightTheme}
          layout={AppLayout}
          dataProvider={configuredDataProvider(setRedirectToLogin)}
          authProvider={authProvider}
        >
          {(permissions) =>
            isAdmin(permissions) ? (
              <>
                <Resource
                    name={"reminders"}
                    recordRepresentation={(record) => `${record.description}`}
                    options={{ label: "Rappels" }}
                    list={AgendaReminderBaseList}
                    icon={NotificationsActive}
                />

                <Resource
                  name={"users"}
                  list={UserList}
                  create={UserCreate}
                  edit={UserEdit}
                  icon={Group}
                  recordRepresentation={(record) =>
                    `${record.firstname} ${record.lastname}`
                  }
                  options={{ label: "Utilisateurs" }}
                />

                <Resource
                  recordRepresentation={(record) => record.name}
                  name={"companies"}
                  icon={Business}
                  list={CompaniesList}
                  show={CompanyShow}
                  options={{ label: "Clients" }}
                />

                <Resource
                  name={"collaborators"}
                  options={{ label: "Usagers" }}
                  recordRepresentation={(record) =>
                    `${record.firstname} ${record.lastname}`
                  }
                  list={CollaboratorList}
                  show={CollaboratorShow}
                />

                <Resource
                  name={"income_types"}
                  recordRepresentation={(record) => record.name}
                  options={{ label: "Types de revenus" }}
                />

                <Resource
                  name={"departments"}
                  recordRepresentation={(record) => record.name}
                  options={{ label: "Equipe" }}
                />

                <Resource
                  name={"outcome_types"}
                  recordRepresentation={(record) => record.name}
                  options={{ label: "Types de charges" }}
                />

                <Resource
                  name={"intervention_types"}
                  recordRepresentation={(record) =>
                    `${record.label} (Code : ${record.code})`
                  }
                  options={{ label: "Gestion Intervention" }}
                  list={InterventionList}
                  edit={DialogEditInterventionType}
                  icon={SettingsIcon}
                />

                <Resource
                  name={"reminder_types"}
                  recordRepresentation={(record) =>
                    `${record.label} (Code : ${record.code})`
                  }
                  options={{ label: "Gestion rappels" }}
                  list={RemindersList}
                  icon={SettingsIcon}
                />

                <Resource
                  name={"thematics"}
                  recordRepresentation={(record) => record.label}
                  options={{ label: "Gestion Thematiques" }}
                  list={ThematicsList}
                  edit={ThematicsEdit}
                  empty={false}
                  icon={SettingsIcon}
                />
              </>
            ) : (
              <CustomRoutes>
                <Route path={"/"} element={<ContractDashboard />} />
              </CustomRoutes>
            )
          }

          <CustomRoutes noLayout={true}>
            {redirectToLogin ? (
              <Route path="/" element={<RedirectToLogin />} />
            ) : null}
            <Route path={"reset_password"}>
              <Route
                path={"request"}
                element={<LoginPage component={RequestPasswordSection} />}
              />
              <Route
                path={"reset"}
                element={<LoginPage component={ResetPasswordSection} />}
              />
            </Route>
            <Route
              path={"initialize"}
              element={<LoginPage component={InitializePasswordSection} />}
            />
          </CustomRoutes>
        </Admin>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

export default App;
