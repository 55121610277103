import { CheckCircleOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import StepperForm from "admin/components/form/StepperForm";
import CollaboratorStepFive from "admin/components/resources/collaborator/form/steps/CollaboratorStepFive";
import CollaboratorStepFour from "admin/components/resources/collaborator/form/steps/CollaboratorStepFour";
import CollaboratorStepOne from "admin/components/resources/collaborator/form/steps/CollaboratorStepOne";
import CollaboratorStepThree from "admin/components/resources/collaborator/form/steps/CollaboratorStepThree";
import CollaboratorStepTwo from "admin/components/resources/collaborator/form/steps/CollaboratorStepTwo";
import { useCallback } from "react";
import { useRecordContext, useRedirect, useResourceContext } from "react-admin";
import { useFormContext } from "react-hook-form";

const formSteps = [
  {
    title: "Identité",
    stepComponent: CollaboratorStepOne,
    add: true,
    edit: true,
  },
  {
    title: "Entreprise",
    stepComponent: CollaboratorStepTwo,
    add: true,
    edit: true,
  },
  {
    title: "Coordonnées",
    stepComponent: CollaboratorStepThree,
    add: true,
    edit: true,
  },
  {
    title: "Situation",
    stepComponent: CollaboratorStepFour,
    add: true,
    edit: true,
  },
  {
    title: "Informations complémentaires",
    stepComponent: CollaboratorStepFive,
    add: true,
    edit: true,
  },
];

const CreatedCollaboratorContent = ({ handleClose }) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const redirect = useRedirect();
  const { reset } = useFormContext();
  const handleRedirectCallback = useCallback(() => {
    redirect("show", "collaborators", record["@id"]);
  }, [redirect, record, resource]);

  const handleCloseCallback = useCallback(() => {
    reset();
    handleClose();
  }, [reset, handleClose]);
  return (
    <>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item>
          <Box mb={2} color={"success.light"}>
            <CheckCircleOutlined color={"inherit"} sx={{ fontSize: 75 }} />
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2}>
            <Typography fontWeight={"bolder"} gutterBottom>
              Que souhaitez-vous faire ensuite ?
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={"column"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid item>
              <Button variant={"contained"} onClick={handleRedirectCallback}>
                Consulter la fiche de l'usager
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleCloseCallback}
                variant={"text"}
                size={"small"}
              >
                Fermer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const CollaboratorFormContainer = ({
  formId,
  handleClose,
  getContext,
}) => {
  return (
    <Box p={2}>
      <StepperForm
        formId={formId}
        formSteps={formSteps}
        formTitle={"Nouveau collaborateur"}
        getContext={getContext}
        afterSuccessContent={
          <CreatedCollaboratorContent handleClose={handleClose} />
        }
        user
      />
    </Box>
  );
};
