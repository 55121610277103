import { Add } from "@mui/icons-material";
import { SaveButton, useRefresh } from "react-admin";
import { useFormContext } from "react-hook-form";
const SaveAndResetButton = () => {
  const { reset } = useFormContext();
  const refresh = useRefresh();
  const onSuccess = () => {
    reset();
    refresh();
  };
  return (
    <SaveButton
      type={"button"}
      icon={<Add />}
      mutationOptions={{ onSuccess }}
      label={"Créer"}
      color={"primary"}
    />
  );
};

export default SaveAndResetButton;
