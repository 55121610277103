import { Grid } from "@mui/material";
import { useEffect } from "react";
import { SelectInput } from "react-admin";
import { useWatch } from "react-hook-form";

const ReminderStepFour = ({ activeStep, currentStep, goToNextStep }) => {
  const { collaborator } = useWatch();

  useEffect(() => {
    if (activeStep === currentStep) {
      goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep]);

  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid item xs={12}>
        <SelectInput
          source="priority"
          label={"Priorité du rappel"}
          fullWidth
          choices={[
            { id: 1, name: "Faible" },
            { id: 2, name: "Moyenne" },
            { id: 3, name: "Haute" },
            { id: 4, name: "Urgente" },
          ]}
          defaultValue={2}
        />
      </Grid>
    </Grid>
  );
};

export default ReminderStepFour;
