import { HistoryToggleOffOutlined } from "@mui/icons-material";
import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { TextField } from "react-admin";

const AverageInterventionTime = () => {
  const {
    palette: { primary, secondary },
  } = useTheme();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Paper
      variant={"outlined"}
      sx={{
        background: `linear-gradient(45deg, ${primary.light} 30%, ${secondary.light} 90%)`,
      }}
    >
      <Box p={3}>
        <Grid
          container
          alignItems={"center"}
          spacing={1}
          direction={"column"}
          justifyContent={"center"}
        >
          <Grid item>
            <TextField
              variant={"h4"}
              component={"span"}
              align={"center"}
              fontWeight={"bolder"}
              color={"white"}
              source={"avgTimeByCollaborator"}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={3}>
                <HistoryToggleOffOutlined
                  sx={{ color: "white", fontSize: "2.5em" }}
                  color={"white"}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography
                  color={"white"}
                  component={"p"}
                  textTransform={"uppercase"}
                  fontWeight={"bolder"}
                >
                  Temps moyen
                </Typography>
                <Typography
                  gutterBottom={false}
                  color={"white"}
                  textTransform={"uppercase"}
                  align={"center"}
                  fontSize={"small"}
                  fontWeight={"bolder"}
                >
                  par usager
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AverageInterventionTime;
