import { Add } from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { memo, useCallback, useEffect, useState } from "react";
import {
  Pagination,
  ReferenceManyField,
  useGetIdentity,
  useRecordContext,
} from "react-admin";
import InterventionCreator from "../../../interventions/form/InterventionCreator";
import InterventionListLayout from "../../../interventions/list";

const InterventionSection = ({ disabled }) => {
  const [defaultSupervisor, setDefaultSupervisor] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [formOpen, setFormOpen] = useState(false);

  const { identity } = useGetIdentity();
  const record = useRecordContext();

  useEffect(() => {
    if (identity) {
      setDefaultSupervisor(identity["@id"]);
    }
  }, [identity, setDefaultSupervisor]);

  useEffect(() => {
    if (record && defaultSupervisor) {
      setInitialValues({
        collaborator: record["@id"],
        author: defaultSupervisor,
      });
    }
  }, [record, defaultSupervisor]);

  const handleFormOpen = useCallback(() => {
    setFormOpen(true);
  }, [setFormOpen]);

  const handleFormClose = useCallback(() => {
    setFormOpen(false);
  }, [setFormOpen]);
  return (
    <>
      <Grid container justifyContent={"space-between"} pr={5}>
        <Grid item>
          <Typography variant={"h6"} color={"primary"}>
            Liste des interventions
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={!identity || disabled}
            onClick={handleFormOpen}
            startIcon={<Add />}
            color={"primary"}
          >
            Nouvelle intervention
          </Button>
        </Grid>
      </Grid>
      <Paper>
        <ReferenceManyField
          reference={"interventions"}
          target={"collaborator"}
          sort={{
            field: "date",
            order: "desc",
          }}
          pagination={<Pagination />}
        >
          <InterventionListLayout disabled={disabled} />
        </ReferenceManyField>
      </Paper>
      {initialValues && (
        <InterventionCreator
          open={formOpen}
          onClose={handleFormClose}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

export default memo(InterventionSection);
