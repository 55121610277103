import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import ContractListLayout from "admin/components/resources/contracts/list";
import { useState } from "react";
import {
  CreateBase,
  DateField,
  DateInput,
  NumberInput,
  ReferenceManyField,
  SimpleForm,
  useRecordContext,
} from "react-admin";
import ContractEditContainer from "./ContractEditContainer";

const ContractContent = () => {
  return (
    <Grid container direction={"column"} spacing={3}>
      <Grid item>
        <Box>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <Grid item>
              <Typography variant={"overline"}>Du :</Typography>
            </Grid>
            <Grid item>
              <DateField
                variant={"overline"}
                source={"startDate"}
                fontWeight={"bold"}
              />
            </Grid>
            <Grid item>
              <Typography variant={"overline"}>Au :</Typography>
            </Grid>
            <Grid item>
              <DateField
                variant={"overline"}
                source={"endDate"}
                fontWeight={"bold"}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const ContractSection = () => {
  const record = useRecordContext();
  const [editRecord, setEditRecord] = useState(null);

  const handleEditClick = (editRecord) => {
    setEditRecord(editRecord);
  };
  const transform = (data) => ({
    ...data,
    company: record["@id"],
  });

  return (
    <Paper>
      <Box pt={1} p={2}>
        <Grid container spacing={2}>
          <Grid item lg={8}>
            <ReferenceManyField reference={"contracts"} target={"company"}>
              <ContractListLayout handleEditClick={handleEditClick} />
            </ReferenceManyField>
          </Grid>
          <Grid item lg={4}>
            {editRecord ? (
              <>
                <Typography align={"center"}>Modifier le contrat</Typography>
                <ContractEditContainer contract={editRecord} />
              </>
            ) : (
              <>
                <Typography align={"center"}>Nouveau contrat</Typography>
                <CreateBase
                  resource={"contracts"}
                  transform={transform}
                  redirect={false}
                >
                  <div>
                    <Card>
                      <SimpleForm
                        defaultValues={{ physicalHours: 0, remoteHours: 0 }}
                      >
                        <DateInput
                          required
                          fullWidth
                          source="startDate"
                          label={"Début du contrat"}
                        />
                        <DateInput
                          required
                          fullWidth
                          source="endDate"
                          label={"Fin du contrat"}
                        />
                        <NumberInput
                          required
                          fullWidth
                          source={"physicalHours"}
                          label={"Heures physiques"}
                        />
                        <NumberInput
                          required
                          fullWidth
                          source={"remoteHours"}
                          label={"Heures distancielles"}
                        />
                      </SimpleForm>
                    </Card>
                  </div>
                </CreateBase>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ContractSection;
