import { Typography } from "@mui/material";

const PageTitle = ({ children }) => {
  return (
    <Typography variant={"h4"} fontWeight={"bolder"}>
      {children}
    </Typography>
  );
};

export default PageTitle;
