import { Typography } from "@mui/material";
import BudgetItemCreate from "admin/components/resources/collaborator/show/budget/BudgetItemCreate";
import BudgetItemForm from "admin/components/resources/collaborator/show/budget/BudgetItemForm";
import { useEffect, useState } from "react";
import { useListContext } from "react-admin";

const BudgetIterator = ({ typeResource, title }) => {
  const { data, isLoading } = useListContext();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!isLoading) {
      let tempTotal = 0;
      data.forEach((budget) => {
        tempTotal += budget.amount;
      });
      if (total !== tempTotal) {
        setTotal(tempTotal);
      }
    }
  }, [data, setTotal, total, isLoading]);

  return (
    <>
      <Typography
        gutterBottom
        sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        align={"center"}
        variant={"h6"}
      >
        {title}
      </Typography>
      <Typography align={"center"}>{total.toFixed(2)} €</Typography>
      <BudgetItemCreate typeResource={typeResource} />
      {data &&
        data.map((budget, index) => {
          return (
            <BudgetItemForm
              index={index}
              record={budget}
              key={budget["@id"]}
              typeResource={typeResource}
            />
          );
        })}
    </>
  );
};

export default BudgetIterator;
