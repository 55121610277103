import { Grid } from "@mui/material";
import ReferenceCreatorInput from "admin/components/general/input/ReferenceCreatorInput/ReferenceCreatorInput";
import SaveAndResetButton from "admin/components/resources/collaborator/show/budget/SaveAndResetButton";
import {
  CreateBase,
  NumberInput,
  SimpleForm,
  useRecordContext,
  useResourceContext,
} from "react-admin";

const BudgetItemCreate = ({ recordResource, typeResource }) => {
  const record = useRecordContext();

  const transformValues = (data) => ({
    ...data,
    collaborator: record["@id"],
  });
  const resource = useResourceContext();
  return (
    <CreateBase
      component={"div"}
      redirect={false}
      actions={false}
      resource={resource}
      transform={transformValues}
    >
      <SimpleForm toolbar={false}>
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item sm={3}>
            <NumberInput
              helperText={false}
              name={"amount"}
              size={"small"}
              variant={"outlined"}
              source={"amount"}
              label={"Montant"}
            />
          </Grid>
          <Grid item>
            <ReferenceCreatorInput
              reference={typeResource}
              ReferenceInputProps={{
                inputProps: { variant: "outlined" },
                variant: "outlined",
                format: (record) => record.name,
                reference: typeResource,
                source: "type",
                label: "Catégorie",
                filter: { pagination: false },
              }}
              AutoCompleteInputProps={{
                name: "type",
                helperText: false,
                size: "small",
                variant: "outlined",
                filterToQuery: (searchText) => ({ name: searchText }),
              }}
              DialogInputProps={{
                title: `Créer une catégorie de ${
                  typeResource === "income_types" ? "revenus" : "charges"
                }`,
              }}
            />
          </Grid>
          <Grid item>
            <SaveAndResetButton />
          </Grid>
        </Grid>
      </SimpleForm>
    </CreateBase>
  );
};

export default BudgetItemCreate;
