import { Box, DialogContentText, ListItemText } from "@mui/material";
import TeamForm from "admin/components/general/input/ReferenceCreatorInput/forms/TeamForm";
import ReferenceCreatorInput from "admin/components/general/input/ReferenceCreatorInput/ReferenceCreatorInput";
import { requiredValidation } from "admin/const/validation";
import { useEffect } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  useRecordContext,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = ["company"];

const TeamOptionText = ({ record: { name, departmentName } }) => {
  return <ListItemText primary={name} secondary={departmentName} />;
};

const CollaboratorStepTwo = ({ activeStep, currentStep, goToNextStep }) => {
  const { company: originalCompany } = useRecordContext() || {};
  const formValues = useWatch();
  const { company } = formValues;
  const { setValue, formState, getFieldState } = useFormContext();

  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (company !== originalCompany) {
      setValue("team", "", { shouldValidate: true });
    }
  }, [company, setValue, originalCompany]);

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });

      if (canGoNext) {
        goToNextStep();
      }
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Informations professionnelles
      </DialogContentText>
      <Box mb={1}>
        <ReferenceInput
          name={"company"}
          source={"company"}
          reference={"companies"}
        >
          <AutocompleteInput
            validate={requiredValidation}
            name={"company"}
            size={"large"}
            label={"Entreprise"}
            filterToQuery={(searchText) => ({ name: searchText })}
          />
        </ReferenceInput>
      </Box>
      <Box mb={1}>
        <ReferenceCreatorInput
          reference="teams"
          ReferenceInputProps={{
            size: "large",
            source: "team",
            name: "team",
            filter: { "department.company": company },
          }}
          AutoCompleteInputProps={{
            size: "large",
            label: "Equipe",
            optionText: (record) => <TeamOptionText record={record} />,
            inputText: (record) => record.name,
            disabled: !company,
            filterToQuery: (searchText) => ({ name: searchText }),
          }}
          DialogInputProps={{
            defaultValues: { company: company },
            title: "Créer une équipe",
            form: TeamForm,
          }}
        />
        <TextInput
          name={"jobTitle"}
          source={"jobTitle"}
          fullWidth
          size={"large"}
          label={"Poste"}
        />
      </Box>
    </>
  );
};

export default CollaboratorStepTwo;
