import ReferenceDialogInput from "admin/components/general/input/ReferenceCreatorInput/ReferenceDialogInput";
import { AutocompleteInput, ReferenceInput } from "react-admin";

const ReferenceCreatorInput = ({
  reference,
  ReferenceInputProps,
  AutoCompleteInputProps,
  DialogInputProps,
}) => {
  return (
    <ReferenceInput reference={reference} {...ReferenceInputProps}>
      <AutocompleteInput
        {...AutoCompleteInputProps}
        create={
          <ReferenceDialogInput reference={reference} {...DialogInputProps} />
        }
      />
    </ReferenceInput>
  );
};

export default ReferenceCreatorInput;
