import {
  AddOutlined,
  EditOutlined,
  GroupWorkOutlined,
  MoreVertOutlined,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import CreatorWizard from "admin/components/resources/departments/CreatorWizard";
import TeamIterator from "admin/components/resources/teams/TeamIterator";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useCallback, useState } from "react";
import { RecordContextProvider, useRecordContext } from "react-admin";

const DepartmentItem = ({ leftPadding = 2, ...props }) => {
  const { "@id": IRI, children, name, active } = useRecordContext();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const levelPadding = leftPadding + 2;

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleEditOpen = useCallback(() => {
    setOpenEdit(true);
  }, [setOpenEdit]);

  const handleEditClose = useCallback(() => {
    setOpenEdit(false);
  }, [setOpenEdit]);

  return (
    <>
      <ListItem sx={{ pl: levelPadding }}>
        <ListItemIcon>
          <GroupWorkOutlined />
        </ListItemIcon>
        <ListItemText primary={name} />
        <ListItemSecondaryAction>
          <PopupState
            popupId={`popup_${IRI}`}
            variant={"popover"}
            disableAutoFocus={false}
          >
            {(popupState) => (
              <React.Fragment>
                <IconButton {...bindTrigger(popupState)}>
                  <MoreVertOutlined />
                </IconButton>
                <Menu {...bindMenu(popupState)} elevation={4} disablePadding>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      handleOpen();
                    }}
                  >
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <AddOutlined />
                      </ListItemIcon>
                      <ListItemText>Créer un élément</ListItemText>
                    </ListItem>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      handleOpen();
                    }}
                  >
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <EditOutlined />
                      </ListItemIcon>
                      <ListItemText>Editer le service</ListItemText>
                    </ListItem>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={true} unmountOnExit>
        {children.length > 0 ? (
          children.map((childTeam) => (
            <RecordContextProvider value={childTeam} key={childTeam["@id"]}>
              <DepartmentItem
                leftPadding={levelPadding}
                key={childTeam["@id"]}
                record={childTeam}
                {...props}
              />
            </RecordContextProvider>
          ))
        ) : (
          <></>
        )}
        <TeamIterator leftPadding={levelPadding} {...props} />
      </Collapse>
      <CreatorWizard open={open} handleClose={handleClose} />
    </>
  );
};

export default React.memo(DepartmentItem);
