import { Grid } from "@mui/material";
import { requiredValidation } from "admin/const/validation";
import { TextInput } from "react-admin";

const InterventionLabelForm = ({ filter, defaultValues }) => {
  return (
    <Grid container direction={"column"} spacing={1}>
      <Grid item>
        <TextInput
          source="label"
          label="Libellé de l'intervention"
          autoFocus
          defaultValue=""
          fullWidth
          helperText={false}
          validate={requiredValidation}
        />
      </Grid>
      <Grid item>
        <TextInput
          source="code"
          label="Code"
          defaultValue={filter.toUpperCase()}
          fullWidth
          helperText={false}
          validate={requiredValidation}
        />
      </Grid>
    </Grid>
  );
};

export default InterventionLabelForm;
