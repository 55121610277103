import { Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useDelete, useRecordContext, useRefresh } from "react-admin";
const DeleteButton = ({
  record: propRecord,
  resource,
  size = "medium",
  disabled,
}) => {
  const recordContext = useRecordContext();
  const record = propRecord || recordContext;
  const refresh = useRefresh();
  const [deleteOne, { isLoading, isSuccess }] = useDelete(resource, {
    id: record.id,
    previousData: record,
  });

  useEffect(() => {
    if (isSuccess) {
      refresh();
    }
  }, [isSuccess, refresh]);

  const handleClick = () => {
    deleteOne();
  };
  return (
    <Button
      color={"error"}
      size={size}
      disabled={isLoading || disabled}
      onClick={handleClick}
    >
      <Delete fontSize={"small"} />
    </Button>
  );
};

export default DeleteButton;
