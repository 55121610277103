import {
  ChatBubbleOutline,
  NotificationsActiveOutlined,
  PeopleOutlined,
  SettingsOutlined,
  TagOutlined,
} from "@mui/icons-material";
import MenuItem from "admin/layout/MenuItem";

const routes = [
  {
    title: "Actions",
    defaultOpen: true,
    children: [
      {
        title: "Mes rappels",
        route: "reminders",
        icon: NotificationsActiveOutlined,
      },
      {
        title: "Clients",
        route: "companies",
        icon: ChatBubbleOutline,
      },
      {
        title: "Collaborateurs",
        route: "collaborators",
        icon: PeopleOutlined,
      },
    ],
  },
  {
    title: "Réglages",
    icon: SettingsOutlined,
    children: [
      {
        title: "Taxonomie",
        icon: TagOutlined,
        children: [
          {
            title: "Interventions",
            route: "intervention_types",
            icon: NotificationsActiveOutlined,
          },
          {
            title: "Rappels",
            route: "reminder_types",
            icon: ChatBubbleOutline,
          },
          {
            title: "Thématiques",
            route: "thematics",
            icon: PeopleOutlined,
          },
        ],
      },
      {
        title: "Utilisateurs",
        route: "users",
        icon: PeopleOutlined,
      },
    ],
  },
];

const MyMenu = () => {
  return (
    <>
      {routes.map((item) => (
        <MenuItem key={item.title} item={item} />
      ))}
    </>
  );
};

export default MyMenu;
