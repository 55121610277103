import {
  AutoGraphOutlined,
  EditOutlined, NotesOutlined,
  RequestPageOutlined,
  SettingsOutlined,
  VpnKeyOutlined,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { CircularProgress, Grid, Paper, Tab } from "@mui/material";
import { ResourceEditor } from "admin/components/form/ResourceForm";
import DashboardAccessSection from "admin/components/resources/companies/show/dashboard_acesses";
import CompanyStatLayout from "admin/components/resources/companies/show/graphs/CompanyStatLayout";
import TreeSection from "admin/components/resources/companies/show/treeview/TreeSection";
import React, { useCallback, useEffect, useState } from "react";
import { useRecordContext, useShowContext } from "react-admin";
import ContractSection from "./contracts";
import NotesContainer from "./infos/notes";

const CompanyContainer = () => {
  const record = useRecordContext();
  const { refetch } = useShowContext();
  const [tabValue, setTabValue] = useState("stats");
  const [showEdit, setShowEdit] = useState(false);

  const handleShowEditClose = useCallback(() => {
    setShowEdit(false);
  }, [setShowEdit]);

  const handleShowEditOpen = useCallback(() => {
    setShowEdit(true);
  }, [setShowEdit]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    refetch();
  }, [refetch]);
  return record ? (
    <>
      <ResourceEditor
        FormContainerProps={{
          open: showEdit,
          maxWidth: "md",
          fullWidth: true,
          onClose: handleShowEditClose,
        }}
        FormProps={{ handleClose: handleShowEditClose }}
      />
      <TabContext value={tabValue}>
        <Paper variant={"outlined"}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <TabList onChange={handleTabChange}>
                <Tab
                  icon={<AutoGraphOutlined />}
                  iconPosition={"start"}
                  value={"stats"}
                  label={"Statistiques"}
                />
                <Tab
                  icon={<SettingsOutlined />}
                  iconPosition={"start"}
                  value={"settings"}
                  label={"Configuration"}
                />
                <Tab
                  icon={<VpnKeyOutlined />}
                  iconPosition={"start"}
                  value={"dashboard_access"}
                  label={"Accès extranet"}
                />
                <Tab
                  icon={<RequestPageOutlined />}
                  iconPosition={"start"}
                  value={"contracts"}
                  label={"Contrats"}
                />
                <Tab
                    icon={<NotesOutlined />}
                    iconPosition={"start"}
                    value={"notes"}
                    label={"Notes internes"}
                />
              </TabList>
            </Grid>
            <Grid item>
              <Tab
                onClick={handleShowEditOpen}
                label={"Editer"}
                iconPosition={"start"}
                icon={<EditOutlined />}
              >
                Editer
              </Tab>
            </Grid>
          </Grid>
        </Paper>
        <TabPanel value={"stats"} sx={{ px: 0 }}>
          <CompanyStatLayout />
        </TabPanel>
        <TabPanel value={"dashboard_access"} sx={{ px: 0 }}>
          <DashboardAccessSection />
        </TabPanel>
        <TabPanel value={"settings"} sx={{ px: 0 }}>
          <TreeSection />
        </TabPanel>
        <TabPanel value={"contracts"} sx={{ px: 0 }}>
          <ContractSection />
        </TabPanel>
        <TabPanel value={"notes"} sx={{ px: 0 }}>
          <NotesContainer />
        </TabPanel>
      </TabContext>
      {/*</Grid>*/}
      {/*</Grid>*/}
    </>
  ) : (
    <CircularProgress />
  );
};

export default React.memo(CompanyContainer);
