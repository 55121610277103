import { CheckCircleOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import StepperForm from "admin/components/form/StepperForm";
import ReminderStepFour from "admin/components/resources/reminders/form/steps/ReminderStepFour";
import ReminderStepOne from "admin/components/resources/reminders/form/steps/ReminderStepOne";
import ReminderStepThree from "admin/components/resources/reminders/form/steps/ReminderStepThree";
import ReminderStepTwo from "admin/components/resources/reminders/form/steps/ReminderStepTwo";
import { useCallback } from "react";
import { useRefresh } from "react-admin";
import { useFormContext } from "react-hook-form";

const formSteps = [
  {
    title: "Date",
    stepComponent: ReminderStepOne,
    add: true,
    edit: true,
  },
  {
    title: "Rappel",
    stepComponent: ReminderStepTwo,
    add: true,
    edit: true,
  },
  {
    title: "Collaborateur",
    stepComponent: ReminderStepThree,
    add: true,
    edit: true,
  },
  {
    title: "Informations complémentaires",
    stepComponent: ReminderStepFour,
    add: true,
    edit: true,
  },
];

const CreatedReminderContent = ({ handleClose }) => {
  const { reset } = useFormContext();
  const refresh = useRefresh();
  const handleCloseCallback = useCallback(() => {
    reset();
    handleClose();
    refresh();
  }, [reset, refresh, handleClose]);
  return (
    <>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item>
          <Box mb={2} color={"success.light"}>
            <CheckCircleOutlined color={"inherit"} sx={{ fontSize: 75 }} />
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2}>
            <Typography fontWeight={"bolder"} gutterBottom>
              Rappel enregistré !
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button onClick={handleCloseCallback} variant={"text"} size={"small"}>
            Fermer
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const ReminderFormContainer = ({ formId, handleClose }) => {
  return (
    <Box p={2}>
      <StepperForm
        formId={formId}
        formSteps={formSteps}
        formTitle={"Nouveau rappel"}
        afterSuccessContent={
          <CreatedReminderContent handleClose={handleClose} />
        }
      />
    </Box>
  );
};
