import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseDialogButton from "admin/components/general/ui/dialog/CloseDialogButton";
import ReminderCreator from "admin/components/resources/reminders/form/ReminderCreator";
import ReminderListLayout from "admin/components/resources/reminders/list/reminderListLayout";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  Pagination,
  ReferenceManyField,
  useGetIdentity,
  useRecordContext,
} from "react-admin";

const filters = {
  toDoFilters: {
    done: false,
    "sendAt[before]": moment(),
    "order[sendAt]": "desc",
    "order[priority]": "desc",
  },
  doneFilter: {
    done: true,
    "order[sendAt]": "desc",
    "order[priority]": "desc",
  },
  allFilters: {
    "order[sendAt]": "desc",
    "order[priority]": "desc",
  },
};

const ReminderDialog = ({ open, handleClose }) => {
  const [defaultSupervisor, setDefaultSupervisor] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("toDoFilters");
  const [formOpen, setFormOpen] = useState(false);

  const { identity } = useGetIdentity();
  const record = useRecordContext();

  useEffect(() => {
    if (identity) {
      setDefaultSupervisor(identity["@id"]);
    }
  }, [identity, setDefaultSupervisor]);

  useEffect(() => {
    if (record && defaultSupervisor) {
      setInitialValues({
        collaborator: record["@id"],
        user: defaultSupervisor,
      });
    }
  }, [record, defaultSupervisor]);

  const handleFormOpen = useCallback(() => {
    setFormOpen(true);
  }, [setFormOpen]);

  const handleFormClose = useCallback(() => {
    setFormOpen(false);
  }, [setFormOpen]);
  const handleSelectedFilter = useCallback(
    (e, value) => {
      setSelectedFilter(value);
    },
    [setSelectedFilter]
  );

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <DialogTitle fontWeight={"bolder"} align={"center"}>
          Liste des rappels
        </DialogTitle>
        <CloseDialogButton handleClose={handleClose} />
        <DialogContent>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <FormControl>
                <RadioGroup
                  row
                  onChange={handleSelectedFilter}
                  value={selectedFilter}
                >
                  <FormControlLabel
                    value={"toDoFilters"}
                    control={<Radio />}
                    label="À faire"
                  />
                  <FormControlLabel
                    value={"doneFilter"}
                    control={<Radio />}
                    label="Faits"
                  />
                  <FormControlLabel
                    value={"allFilters"}
                    control={<Radio />}
                    label="Tous les rappels"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item justifySelf={"end"}>
              <Button
                disabled={!identity}
                onClick={handleFormOpen}
                startIcon={<Add />}
                color={"primary"}
              >
                Nouveau rappel
              </Button>
            </Grid>
          </Grid>

          <ReferenceManyField
            reference={"reminders"}
            target={"collaborator"}
            pagination={<Pagination />}
            filter={filters[selectedFilter]}
          >
            <ReminderListLayout showFolder={true} />
          </ReferenceManyField>
        </DialogContent>
      </Dialog>
      {initialValues && (
        <ReminderCreator
          open={formOpen}
          onClose={handleFormClose}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

export default ReminderDialog;
