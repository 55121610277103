import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import TeamForm from "admin/components/general/input/ReferenceCreatorInput/forms/TeamForm";
import ReferenceCreatorInput from "admin/components/general/input/ReferenceCreatorInput/ReferenceCreatorInput";
import { lastNameValidation, requiredValidation } from "admin/const/validation";
import React, { useEffect } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  CreateBase,
  EditBase,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const FormSubtitle = ({ children }) => {
  return (
    <Box mb={1}>
      <Typography component={"p"} variant={"overline"}>
        {children}
      </Typography>
    </Box>
  );
};

const CompanyFormContainer = () => {
  const { company: originalCompany } = useRecordContext() || {};
  const { company } = useWatch();
  const { setValue } = useFormContext();
  useEffect(() => {
    if (company !== originalCompany) {
      setValue("team", "", { shouldValidate: true });
    }
  }, [company, setValue, originalCompany]);
  return (
    <>
      <Box mb={1}>
        <ReferenceInput
          name={"company"}
          source={"company"}
          reference={"companies"}
        >
          <AutocompleteInput
            validate={requiredValidation}
            name={"company"}
            size={"small"}
            label={"Entreprise"}
            filterToQuery={(searchText) => ({ name: searchText })}
          />
        </ReferenceInput>
      </Box>
      <Box mb={1}>
        <ReferenceCreatorInput
          reference="teams"
          ReferenceInputProps={{
            size: "small",
            source: "team",
            name: "team",
            filter: { company: company },
          }}
          AutoCompleteInputProps={{
            validate: requiredValidation,
            size: "small",
            label: "Equipe",
            disabled: !company,
            filterToQuery: (searchText) => ({ name: searchText }),
          }}
          DialogInputProps={{
            defaultValues: { company: company },
            title: "Créer une équipe",
            form: TeamForm,
          }}
        />
        <TextInput
          validate={lastNameValidation}
          name={"jobTitle"}
          source={"jobTitle"}
          fullWidth
          label={"Poste"}
        />
      </Box>
    </>
  );
};

const FormContainer = ({ handleClose }) => {
  const record = useRecordContext();

  return (
    <>
      <DialogTitle>
        {record
          ? `Edition de ${record.firstname} ${record.lastname}`
          : `Création d'un nouveau type de rappel`}
      </DialogTitle>
      <DialogContent>
        <FormSubtitle>Informations générales</FormSubtitle>
        <Box mb={1}>
          <TextInput
            validate={requiredValidation}
            placeholder={"OMNIS"}
            name={"code"}
            fullWidth
            source={"code"}
            label={"Code"}
          />
        </Box>
        <Box mb={1}>
          <TextInput
            validate={requiredValidation}
            placeholder={"Sit corrupti non omnis et."}
            name={"label"}
            fullWidth
            source={"label"}
            label={"Intitulé"}
          />
        </Box>
        <Box mb={1}>
          <BooleanInput
            source={"active"}
            label="Inactif / Actif"
            defaultValue
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button color={"error"} onClick={handleClose}>
              Annuler
            </Button>
          </Grid>
          <Grid item>
            <SaveButton
              variant={"text"}
              form={"create_reminder_type_form"}
              label={record ? "Sauvegarder les modifications" : "Créer"}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export const ReminderTypeForm = ({ id, handleClose, open, record }) => {
  return (
    <SimpleForm
      toolbar={false}
      component={"div"}
      mode={"onBlur"}
      reValidateMode={"onChange"}
      id={id}
    >
      <Dialog open={open} onClose={handleClose}>
        <FormContainer handleClose={handleClose} />
      </Dialog>
    </SimpleForm>
  );
};

const DialogAddReminderType = ({ open, handleClose }) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("Nouveau Type enregistré");
    handleClose();
  };
  return (
    <CreateBase
      resource={"reminder_types"}
      component={React.Fragment}
      mutationOptions={{ onSuccess }}
    >
      <ReminderTypeForm
        id={"create_reminder_type_form"}
        handleClose={handleClose}
        open={open}
      />
    </CreateBase>
  );
};

export const CollaboratorEditDialog = ({ open, handleClose }) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("Modifications enregistrées");
    handleClose();
  };

  return (
    <EditBase
      resource={"collaborators"}
      component={React.Fragment}
      mutationOptions={{ onSuccess }}
    >
      <ReminderTypeForm
        id={"create_reminder_type_form"}
        handleClose={handleClose}
        open={open}
      />
    </EditBase>
  );
};

export default DialogAddReminderType;
