import { Container, Dialog, useMediaQuery } from "@mui/material";
import RemindersListAside from "admin/components/resources/reminders/list/RemindersListAside";
import PageTitle from "admin/layout/PageTitle";
import { useCallback, useState } from "react";
import { List } from "react-admin";
import AgendaIterator from "./AgendaIterator";

const ListFilters = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <RemindersListAside mobile />
    </Dialog>
  );
};

const AgendaReminderBaseList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return (
    <Container maxWidth={"xl"}>
      <List
        actions={false}
        component={"div"}
        empty={false}
        pagination={false}
        filters={<ListFilters open={open} handleClose={handleClose} />}
        title={<PageTitle>Mes rappels</PageTitle>}
      >
        <AgendaIterator handleOpen={handleOpen} />
      </List>
    </Container>
  );
};

export default AgendaReminderBaseList;
