import {Grid, InputLabel, MenuItem, Paper, Select} from "@mui/material";
import ContractProgress from "admin/components/resources/companies/show/graphs/graph/ContractProgress";
import AppointmentTotal from "admin/components/resources/companies/show/graphs/widget/AppointmentTotal";
import AverageInterventionByCollaborator
    from "admin/components/resources/companies/show/graphs/widget/AverageInterventionByCollaborator";
import AverageInterventionTime from "admin/components/resources/companies/show/graphs/widget/AverageInterventionTime";
import CreatedCollaborator from "admin/components/resources/companies/show/graphs/widget/CreatedCollaborator";
import {ReferenceField, ReferenceInput, ShowBase, useGetList, useRecordContext} from "react-admin";
import ThematicRepartition from "./graph/ThematicRepartition";
import StatLayout from "./StatLayout";
import {useState} from "react";
import moment from "moment";
import Box from "@mui/material/Box";

const CompanyStatLayout = () => {
    const company = useRecordContext();
    const [selectedContract, setSelectedContract] = useState(company?.currentContract);

    const {data, total, isLoading, error} = useGetList(
        'contracts',
        {
            filter: {company: company['@id']}
        }
    );

    const handleChange = (e) => {
        setSelectedContract(e.target.value);
    }
    console.log(selectedContract);
    return (
        <Grid container direction={"column"}>
            <Grid item>
                {data &&
                    <Box mb={2}>
                        <Paper>
                            <Box p={2}>
                                <Grid container justifyContent={"flex-end"}>
                                    <Select
                                        id="contract-select"
                                        value={selectedContract}
                                        variant={"outlined"}
                                        onChange={handleChange}
                                    >
                                        {data.map((contract) => <MenuItem key={contract['@id']} value={contract['@id']}>Contrat
                                            du {moment(contract.startDate).format('DD/MM/Y')} au {moment(contract.endDate).format('DD/MM/Y')}</MenuItem>)}
                                    </Select>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                        }
                    </Grid>
                    <Grid item>
                {selectedContract ?
                    <ShowBase resource={"contracts"} id={selectedContract}>
                <StatLayout/>
            </ShowBase>
            : <></>
            }
        </Grid>

</Grid>

)
;
};

export default CompanyStatLayout;
