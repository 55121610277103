import { Add } from "@mui/icons-material";
import { Button, Grid, Paper } from "@mui/material";
import StatusTogglerButton from "admin/components/general/buttons/StatusTogglerButton";
import AvatarField from "admin/components/general/fields/AvatarField";
import DashboardAccessCreator from "admin/components/resources/dashboardAccess/form/DashboardAccessCreator";
import { useState } from "react";
import { Datagrid, ListBase, TextField, useRecordContext } from "react-admin";
import DeleteButton from "../../../../general/buttons/DeleteButton";

const DashboardAccessSection = () => {
  const record = useRecordContext();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper elevation={4}>
      <Grid container direction={"row-reverse"}>
        <Button color={"primary"} onClick={handleOpen} startIcon={<Add />}>
          Nouvel accès
        </Button>
        <DashboardAccessCreator open={open} handleClose={handleClose} />
      </Grid>
      <ListBase
        filter={{ company: record["@id"] }}
        resource={"dashboard_accesses"}
      >
        <Datagrid bulkActionButtons={false}>
          <AvatarField label={false} source={"fullName"} />
          <TextField label={"Nom"} source={"fullName"} />
          <TextField label={"Email"} source={"email"} />
          <StatusTogglerButton />
          <DeleteButton resource={'dashboard_accesses'} />
        </Datagrid>
      </ListBase>
    </Paper>
  );
};

export default DashboardAccessSection;
