import { ResourceEditor } from "admin/components/form/ResourceForm";
import { useCallback } from "react";
import { RecordContextProvider, useRecordContext } from "react-admin";

const InterventionEditor = ({ showEdit, setShowEdit }) => {
  const record = useRecordContext();
  const handleShowEditClose = useCallback(() => {
    setShowEdit(false);
  }, [setShowEdit]);
  return (
    <RecordContextProvider value={record}>
      <ResourceEditor
        FormContainerProps={{
          open: showEdit,
          maxWidth: "md",
          fullWidth: true,
          onClose: handleShowEditClose,
          TransitionProps: {
            mountOnEnter: true,
            unmountOnExit: true,
          },
        }}
        FormProps={{ handleClose: handleShowEditClose }}
      />
    </RecordContextProvider>
  );
};

export default InterventionEditor;
