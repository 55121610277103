import { ResourceCreator } from "admin/components/form/ResourceForm";
import { ResourceContextProvider } from "react-admin";

const InterventionCreator = ({ open, onClose, initialValues }) => {
  return (
    <ResourceContextProvider value={"interventions"}>
      <ResourceCreator
        FormContainerProps={{
          open,
          maxWidth: "md",
          fullWidth: true,
          onClose,
          TransitionProps: {
            mountOnEnter: true,
            unmountOnExit: true,
          },
        }}
        FormProps={{ handleClose: onClose }}
        initialValues={initialValues}
        resource={"interventions"}
      />
    </ResourceContextProvider>
  );
};

export default InterventionCreator;
