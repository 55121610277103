import { Grid, Typography } from "@mui/material";
import FileFormDialog from "admin/components/resources/files/form";

const FileEmptyList = ({ resource }) => {
  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Typography variant={"h6"} fontWeight={"bolder"} align={"center"}>
          Aucun fichier
        </Typography>
        <Typography variant={"body1"} align={"center"}>
          La liste des fichiers est vide. Ajoutez en un !
        </Typography>
      </Grid>
      <Grid item>
        <FileFormDialog resource={resource} />
      </Grid>
    </Grid>
  );
};

export default FileEmptyList;
