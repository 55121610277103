import { DialogContentText, Grid } from "@mui/material";
import {
  firstnameValidation,
  lastNameValidation,
} from "admin/const/validation";
import { useEffect } from "react";
import { DateInput, TextInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = ["firstname", "lastname"];

const CollaboratorStepOne = ({ activeStep, goToNextStep, currentStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();
  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });
  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });

      if (canGoNext) {
        goToNextStep();
      }
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);
  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Qui est le nouvel usager ?
      </DialogContentText>
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <TextInput
            validate={firstnameValidation}
            placeholder={"Michel"}
            fullWidth
            name={"firstname"}
            source={"firstname"}
            size={"large"}
            label={"Prénom"}
          />
        </Grid>
        <Grid xs={6} item>
          <TextInput
            validate={lastNameValidation}
            placeholder={"Dumas"}
            name={"lastname"}
            source={"lastname"}
            label={"Nom"}
            fullWidth
            size={"large"}
          />
        </Grid>
      </Grid>
      <DateInput
        fullWidth
        name={"dateOfBirth"}
        source={"dateOfBirth"}
        size={"large"}
        label={"Date de naissance"}
      />
    </>
  );
};

export default CollaboratorStepOne;
