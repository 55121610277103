import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Grow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { emailValidation } from "admin/const/validation";
import { useState } from "react";
import { PasswordInput, SimpleForm, TextInput, useLogin } from "react-admin";
import { Link } from "react-router-dom";

const LoginSection = () => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const login = useLogin();
  const handleSubmit = ({ email, password }) => {
    setLoading(true);
    login({ email, password }).catch(() => {
      setShowError(true);
      setLoading(false);
    });
  };
  const {
    palette: { primary, secondary },
  } = useTheme();
  return (
    <Grid container direction={"column"} alignItems={"center"}>
      <Grid item>
        <Box>
          <Box mb={4}>
            <Typography align={"center"} gutterBottom>
              Avant de commencer, il faut vous identifier !
            </Typography>
          </Box>

          <Grow in={showError} mountOnEnter unmountOnExit>
            <Box my={3}>
              <Alert severity={"error"} sx={{ width: "100%" }}>
                <AlertTitle>Connexion impossible</AlertTitle>
                Identifiants invalides
              </Alert>
            </Box>
          </Grow>

          <SimpleForm onSubmit={handleSubmit} toolbar={false} component={"div"}>
            <TextInput
              validate={emailValidation}
              fullWidth
              type={"email"}
              size={"large"}
              source={"email"}
              name={"email"}
              label={"Mon adresse e-mail"}
              autoFocus
            />
            <PasswordInput
              required
              fullWidth
              size={"large"}
              name={"password"}
              source={"password"}
              label={"Mot de passe"}
            />
            <LoadingButton
              type="submit"
              fullWidth
              sx={{
                width: "100%",
                padding: 2,
                background: `linear-gradient(45deg, ${primary.main} 30%, ${primary.light} 90%)`,
              }}
              size={"large"}
              loading={loading}
              variant={"contained"}
            >
              Me connecter
            </LoadingButton>
          </SimpleForm>
        </Box>
        <Box mb={2} />
      </Grid>
      <Grid item>
        <Button component={Link} to={"/reset_password/request"}>
          J'ai oublié mon mot de passe 😔
        </Button>
      </Grid>
    </Grid>
  );
};

export default LoginSection;
