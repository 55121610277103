import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FileDialog from "admin/components/resources/collaborator/show/files/FileDialog";
import { useCallback, useState } from "react";
import { useRecordContext } from "react-admin";

const FileSummary = () => {
  const { filesTotal } = useRecordContext();
  const [open, setOpen] = useState(false);
  const handleDialogOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleDialogClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Tooltip arrow title={"Gérer les fichiers"}>
        <Paper variant={"outlined"} sx={{ height: "100%" }}>
          <Grid container justifyContent={"stretch"} sx={{ height: "inherit" }}>
            <Grid item sm={12}>
              <Button
                sx={{ width: "100%", height: "100%" }}
                variant={"text"}
                onClick={handleDialogOpen}
              >
                <Box p={2}>
                  <Grid container alignItems={"center"} direction={"column"}>
                    <Grid item>
                      <Typography variant={"overline"} color={"primary"}>
                        Fichiers
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant={"h2"}
                        align={"center"}
                        fontWeight={"bolder"}
                        color={"primary"}
                      >
                        {filesTotal}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        color={"primary"}
                        variant={"h6"}
                        fontWeight={"lighter"}
                        align={"center"}
                      >
                        disponible{filesTotal > 1 ? "s" : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Tooltip>
      <FileDialog open={open} handleClose={handleDialogClose} />
    </>
  );
};

export default FileSummary;
