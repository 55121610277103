import { Button, ButtonGroup } from "@mui/material";
import { useCallback } from "react";
import { useController } from "react-hook-form";

const ButtonGroupSelectInput = ({ name, label, choices = [] }) => {
  const {
    field: { onChange, value },
  } = useController({ name: name });

  const handleButtonCallback = useCallback(
    (buttonValue) => {
      onChange(buttonValue);
    },
    [onChange]
  );

  return (
    <ButtonGroup>
      {choices.map(({ label, value: buttonValue }) => (
        <Button
          onClick={() => handleButtonCallback(buttonValue)}
          variant={value === buttonValue ? "contained" : "outlined"}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonGroupSelectInput;
