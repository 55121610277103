import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useController } from "react-hook-form";

const TimePickerInput = ({ label, name, ...inputProps }) => {
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useController({
    name,
    defaultValue: moment.utc().set({ hours: 0, minute: 30 }),
  });

  return (
    <TimePicker
      label={label}
      value={moment.utc(field.value)}
      ampm={false}
      minutesStep={10}
      inputProps={{ fullWidth: true }}
      showToolbar
      onChange={field.onChange}
      renderInput={(params) => (
        <TextField {...params} {...field} {...inputProps} fullWidth />
      )}
    />
  );
};
export default TimePickerInput;
