import TeamItem from "admin/components/resources/teams/TeamItem";
import { RecordContextProvider, useRecordContext } from "react-admin";

const TeamItemIterator = ({
  showInactive,
  leftPadding = 2,
  handleSelected,
}) => {
  const { teams } = useRecordContext();
  return (
    <>
      {teams
        .filter((team) => showInactive || team.active)
        .map((team) => {
          return (
            <RecordContextProvider value={team} key={team["@id"]}>
              <TeamItem
                leftPadding={leftPadding}
                handleSelected={handleSelected}
              />
            </RecordContextProvider>
          );
        })}
    </>
  );
};

export default TeamItemIterator;
