import { Card } from "@mui/material";
import {
  DateInput,
  EditBase,
  NumberInput,
  RecordContextProvider,
  ResourceContextProvider,
  SimpleForm,
} from "react-admin";

const ContractEditContainer = ({ contract }) => {
  return (
    <ResourceContextProvider value={"contracts"}>
      <RecordContextProvider value={contract}>
        <EditBase resource={"contracts"} id={contract["@id"]}>
          <div>
            <Card>
              <SimpleForm record={contract} resource={"contracts"}>
                <DateInput
                  required
                  fullWidth
                  source="startDate"
                  label={"Début du contrat"}
                />
                <DateInput
                  required
                  fullWidth
                  source="endDate"
                  label={"Fin du contrat"}
                />
                <NumberInput
                  required
                  fullWidth
                  source={"physicalHours"}
                  label={"Heures physiques"}
                />
                <NumberInput
                  required
                  fullWidth
                  source={"remoteHours"}
                  label={"Heures distancielles"}
                />
              </SimpleForm>
            </Card>
          </div>
        </EditBase>
      </RecordContextProvider>
    </ResourceContextProvider>
  );
};

export default ContractEditContainer;
