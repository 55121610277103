import { Box, DialogContentText, Grid, ListItemText } from "@mui/material";
import ReminderLabelForm from "admin/components/general/input/ReferenceCreatorInput/forms/ReminderLabelForm";
import ReferenceCreatorInput from "admin/components/general/input/ReferenceCreatorInput/ReferenceCreatorInput";
import { requiredValidation } from "admin/const/validation";
import { useEffect } from "react";
import { TextInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = ["type", "description"];

const ReminderStepTwo = ({ activeStep, currentStep, goToNextStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();

  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });
      if (canGoNext) goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Contenu du rappel
      </DialogContentText>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ReferenceCreatorInput
              reference={"reminder_types"}
              fullwidth
              ReferenceInputProps={{
                reference: "reminder_types",
                source: "type",
                label: "Libellé",
                name: "type",
                filter: { pagination: false },
              }}
              AutoCompleteInputProps={{
                validate: requiredValidation,
                name: "type",
                label: "Libellé du rappel",
                size: "small",
                filterToQuery: (searchText) => ({ code: searchText }),
                optionText: (record) => (
                  <ListItemText
                    primary={record.code}
                    secondary={record.label}
                  />
                ),
                inputText: (record) => record.label,
              }}
              DialogInputProps={{
                title: "Créer un libellé",
                form: ReminderLabelForm,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              validate={requiredValidation}
              name={"description"}
              fullWidth
              source={"description"}
              label={"Description du rappel"}
              multiline
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReminderStepTwo;
