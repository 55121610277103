import { CloudDownload } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { getApiAbsoluteRoute } from "admin/const/utils";
import { useRecordContext } from "react-admin";

const DownloadFileField = ({ source, size = "small", text }) => {
  const record = useRecordContext();

  const handleClick = (filename) => {
    const url = getApiAbsoluteRoute(record[source]);

    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      //Add type to blob parameters if fileformat isn't infered
      const blob = new Blob([req.response]);

      const url = window.URL;
      const link = url.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", filename);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChid(a);
    };
    req.send();
  };

  return (
    <IconButton size={size} onClick={() => handleClick(record.originalName)}>
      <CloudDownload />
    </IconButton>
  );
};

export default DownloadFileField;
