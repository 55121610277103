export function createLinearGradient(ctx, area, colors) {
  const colorStart = colors[0];
  const colorEnd = colors[1];
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const getRandomColor = (color) => {
  let p = 1,
    temp,
    random = Math.random(),
    result = "#";

  while (p < color.length) {
    temp = parseInt(color.slice(p, (p += 2)), 16);
    temp += Math.floor((255 - temp) * random);
    result += temp.toString(16).padStart(2, "0");
  }
  return result;
};

export function createRadialGradient3(ctx, chartArea, c1) {
  if (!chartArea) {
    // This case happens on initial chart load
    return;
  }

  const centerX = (chartArea.left + chartArea.right) / 2;
  const centerY = (chartArea.top + chartArea.bottom) / 2;
  const r = Math.min(
    (chartArea.right - chartArea.left) / 2,
    (chartArea.bottom - chartArea.top) / 2
  );
  const gradient = ctx.createRadialGradient(
    centerX,
    centerY,
    0.2,
    centerX,
    centerY,
    r
  );
  gradient.addColorStop(0, getRandomColor(c1));
  gradient.addColorStop(0.5, getRandomColor(c1));
  gradient.addColorStop(1, c1);

  return gradient;
}

export function createStroke(ctx, bgColor) {
  const _stroke = ctx.stroke;
  ctx.stroke = function () {
    ctx.save();
    ctx.shadowColor = bgColor;
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    _stroke.apply(this, arguments);
    ctx.restore();
  };

  const _fill = ctx.fill;
  ctx.fill = function () {
    ctx.save();
    ctx.shadowColor = bgColor;
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    _fill.apply(this, arguments);
    ctx.restore();
  };
}

export const strokePlugin = {
  plugins: [
    {
      id: "strokePlugin",
      beforeDraw: (chart, args, options) => {
        let text = options.consoleText || "fillerConsoleText";
        console.log(text);
      },
    },
  ],
};
