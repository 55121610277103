import { Create, SimpleForm, TextInput } from "react-admin";

const UserCreate = () => {
  return (
    <Create resource={"users"}>
      <SimpleForm>
        <TextInput label={"Prénom"} name={"firstname"} source={"firstname"} />
        <TextInput label={"Nom"} name={"lastname"} source={"lastname"} />
        <TextInput
          label={"E-mail"}
          name={"email"}
          source={"email"}
          type={"email"}
        />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
