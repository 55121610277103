import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import {
  Datagrid,
  DateField,
  NumberField,
  useRecordContext,
} from "react-admin";

const ContractListLayout = ({ handleEditClick }) => (
  <Datagrid bulkActionButtons={false}>
    <DateField source={"startDate"} label={"Début"} />
    <DateField source={"endDate"} label={"Fin"} />
    <NumberField source={"physicalHours"} label={"Présentiel"} />
    <NumberField source={"remoteHours"} label={"A distance"} />
    <EditContractButton handleEditClick={handleEditClick} />
  </Datagrid>
);

const EditContractButton = ({ handleEditClick }) => {
  const record = useRecordContext();
  const onClick = () => {
    handleEditClick(record);
  };
  return (
    <IconButton onClick={onClick}>
      <EditIcon />
    </IconButton>
  );
};
export default ContractListLayout;
