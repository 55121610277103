import { Add } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid } from "@mui/material";
import {
  default as DialogAddInterventionType,
  default as DialogEditInterventionType,
} from "admin/components/resources/field_management/interventions/form";
import { useCallback, useState } from "react";
import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  useRecordContext,
} from "react-admin";

const ListActions = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Grid container direction={"row-reverse"}>
      <Grid item>
        <Button color={"primary"} onClick={handleOpen} startIcon={<Add />}>
          Nouveau type d'interventions
        </Button>
        <DialogAddInterventionType open={open} handleClose={handleClose} />
      </Grid>
    </Grid>
  );
};

const EditIntervention = () => {
  const record = useRecordContext();

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Grid container direction={"row-reverse"}>
      {/* {record['@id']} */}
      <Grid item>
        <Button
          color={"primary"}
          onClick={handleOpen}
          startIcon={<EditIcon />}
        ></Button>
        <DialogEditInterventionType
          open={open}
          id={`edit_${record["@id"]}`}
          handleClose={handleClose}
        />
      </Grid>
    </Grid>
  );
};

const InterventionsList = () => (
  <List
    hasShow={false}
    hasEdit={false}
    empty={false}
    actions={<ListActions />}
    title={"Liste des rappels"}
  >
    <Datagrid bulkActionButtons={false} stickyHeader={false}>
      <TextField source={"code"} label={"Code"} />
      <TextField source={"label"} label={"Label"} />
      <BooleanField source={"active"} label="Actif" />
      <EditIntervention />
      {/* <BooleanInput label="Actif" /> */}
      {/* <ShowButton resource={"collaborators"} label={""} /> */}
    </Datagrid>
  </List>
);

export default InterventionsList;
