import { Dialog, DialogContent, DialogTitle, Grow } from "@mui/material";
import CloseDialogButton from "admin/components/general/ui/dialog/CloseDialogButton";
import FileList from "admin/components/resources/files/list";
import { useRecordContext } from "react-admin";

const FileDialog = ({ open, handleClose }) => {
  const record = useRecordContext();
  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Grow}
    >
      <DialogTitle fontWeight={"bolder"} align={"center"}>
        Liste des fichiers
      </DialogTitle>
      <CloseDialogButton handleClose={handleClose} />
      <DialogContent>
        <FileList
          component="div"
          filter={{ itemsPerPage: 10, createdAt: "desc" }}
          resource={`${record["@id"].substring(1)}/files`}
        />
      </DialogContent>
    </Dialog>
  );
};

export default FileDialog;
