import { Edit, SimpleForm, TextInput } from "react-admin";

const UserEdit = () => {
  return (
    <Edit resource={"users"}>
      <SimpleForm>
        <TextInput label={"Prénom"} name={"firstname"} source={"firstname"} />
        <TextInput label={"Nom"} name={"lastname"} source={"lastname"} />
        <TextInput
          label={"E-mail"}
          name={"email"}
          source={"email"}
          type={"email"}
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
