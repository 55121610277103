import { ResourceEditor } from "admin/components/form/ResourceForm";
import {
  RecordContextProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

const TeamEditor = ({ open, handleClose }) => {
  const team = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const onSuccess = () => {
    refresh();
    handleClose();
    notify("L'équipe a été mise à jour", "success");
  };
  return (
    <RecordContextProvider value={team}>
      <ResourceEditor
        FormContainerProps={{
          open,
          maxWidth: "xs",
          fullWidth: true,
          onClose: handleClose,
        }}
        FormProps={{ handleClose }}
        BaseComponentProps={{ mutationOptions: { onSuccess } }}
      />
    </RecordContextProvider>
  );
};

export default TeamEditor;
