import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { getThematicConfig } from "admin/const/thematics";
import { useRecordContext } from "react-admin";
import DepartmentRepartition from "./DepartmentRepartition";

const ThematicRepartition = () => {
  const { thematicRepartition, interventionCount } = useRecordContext();

  return (
    <Paper sx={{ height: "100%" }}>
      <Box p={2} pt={2} pb={2}>
        <Typography variant={"h6"} fontWeight={"bolder"} align={"center"}>
          {interventionCount} Interventions
        </Typography>
      </Box>

      <Grid container>
        <Grid item lg={6}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Box px={4}>
                <List dense>
                  {thematicRepartition
                    .sort((a, b) => {
                      if (a.value < b.value) {
                        return 1;
                      } else {
                        return -1;
                      }
                    })
                    .map((thematicRep) => {
                      const { icon: Icon, color } = getThematicConfig(
                        thematicRep.label
                      );
                      return (
                        <ListItem key={thematicRep["@id"]}>
                          <ListItemIcon>
                            <Icon sx={{ color }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={thematicRep.label}
                            primaryTypographyProps={{ fontSize: "large" }}
                          />
                          <ListItemSecondaryAction>
                            <ListItemText
                              primaryTypographyProps={{
                                fontWeight: "bolder",
                                fontSize: "large",
                              }}
                              primary={thematicRep.value}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6}>
          <DepartmentRepartition />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ThematicRepartition;
