import { Box, Button, Grid, Paper, Tooltip, Typography } from "@mui/material";
import ReminderDialog from "admin/components/resources/collaborator/show/reminder/ReminderDialog";
import { useCallback, useState } from "react";
import { useRecordContext } from "react-admin";

const ReminderSummary = () => {
  const { reminderSummary } = useRecordContext();
  const [openReminderDialog, setOpenReminderDialog] = useState(false);
  const handleDialogOpen = useCallback(() => {
    setOpenReminderDialog(true);
  }, [setOpenReminderDialog]);

  const handleDialogClose = useCallback(() => {
    setOpenReminderDialog(false);
  }, [setOpenReminderDialog]);

  return (
    <>
      <Tooltip arrow title={"Gérer les rappels"}>
        <Paper variant={"outlined"} sx={{ height: "100%" }}>
          <Grid container justifyContent={"stretch"} sx={{ height: "inherit" }}>
            <Grid item sm={12}>
              <Button
                sx={{ width: "100%", height: "100%" }}
                variant={"text"}
                onClick={handleDialogOpen}
              >
                <Box p={2}>
                  <Grid container alignItems={"center"} direction={"column"}>
                    <Grid item>
                      <Typography variant={"overline"} color={"primary"}>
                        Rappels
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant={"h2"}
                        align={"center"}
                        fontWeight={"bolder"}
                        color={"primary"}
                      >
                        {reminderSummary}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        color={"primary"}
                        variant={"h6"}
                        fontWeight={"lighter"}
                        align={"center"}
                      >
                        A traiter
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Tooltip>
      <ReminderDialog
        open={openReminderDialog}
        handleClose={handleDialogClose}
      />
    </>
  );
};

export default ReminderSummary;
