import { Drawer as MuiDrawer, List } from "@mui/material";
import { styled } from "@mui/styles";
import logo from "assets/img/logo.png";
import { useSidebarState } from "react-admin";

export const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  border: 0,
  marginBottom: theme.spacing(0),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  marginRight: theme.spacing(1),
  borderTop: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppDrawer = ({ children }) => {
  const [open, setOpen] = useSidebarState();
  const toggleSidebar = () => setOpen(!open);
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <img src={logo} alt="Logo du groupe néholys" style={{ width: "50%" }} />
      </DrawerHeader>
      <List disablePadding>{children}</List>
    </Drawer>
  );
};

export default AppDrawer;
