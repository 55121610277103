import { entrypoint, getHeaders } from "admin/config/dataProvider";
import { default as jwtDecode, default as jwt_decode } from "jwt-decode";

const getUserInformation = () => {
  const request = new Request(`${entrypoint}/me`, {
    method: "GET",
    headers: getHeaders(),
  });
  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((identity) => {
      localStorage.setItem("loggedUser", JSON.stringify(identity));
      window.location.reload();
      return identity;
    });
};

const authProvider = {
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loggedUser");
    return Promise.resolve();
  },
  login: ({ email, password }) => {
    const request = new Request(`${entrypoint}/login`, {
      method: "POST",
      body: JSON.stringify({ email: email, password }),
      headers: new Headers({ "Content-Type": "application/ld+json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem("token", token);
      })
      .then(() => {
        getUserInformation().then();
      });
  },

  checkAuth: () => {
    try {
      if (!localStorage.getItem("token")) {
        return Promise.reject();
      } else {
        if (
          new Date().getTime() / 1000 > // @ts-ignore
          jwtDecode(localStorage.getItem("token"))?.exp
        ) {
          return Promise.reject();
        }
      }
      return Promise.resolve();
    } catch (e) {
      // override possible jwtDecode error
      return Promise.reject();
    }
  },
  checkError: (err) => {
    if ([401].includes(err?.status || err?.response?.status)) {
      localStorage.removeItem("token");
      localStorage.removeItem("loggedUser");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: () => {
    const loggedUser = localStorage.getItem("loggedUser");
    if (!loggedUser) {
      const request = new Request(`${entrypoint}/me`, {
        method: "GET",
        headers: getHeaders(),
      });
      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((identity) => {
          localStorage.setItem("loggedUser", JSON.stringify(identity));
          return identity;
        });
    } else {
      return JSON.parse(loggedUser);
    }
  },
  getPermissions: () => {
    const token = localStorage.getItem("token");
    if (token) {
      const { roles } = jwt_decode(token);
      return roles;
    } else {
      return [];
    }
  },
};

export default authProvider;
