import {
  CircularProgress,
  CssBaseline,
  Grid,
  Grow,
  Typography,
} from "@mui/material";
import { useGetIdentity } from "react-admin";

const LoadingPage = () => {
  const { identity } = useGetIdentity();
  return (
    <>
      <CssBaseline />
      <Grid
        direction={"column"}
        spacing={5}
        container
        sx={{ height: "100vh", width: "100vw" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item>
          <CircularProgress color={"secondary"} size={150} />
        </Grid>
        <Grid item>
          <Grow in={!!identity}>
            <div>
              <Typography align={"center"} variant={"h5"} color={"secondary"}>
                Bonjour {identity?.firstname} !
              </Typography>
              <Typography variant={"overline"} color={"secondary"}>
                Nous préparons votre application
              </Typography>
            </div>
          </Grow>
        </Grid>
      </Grid>
    </>
  );
};

export default LoadingPage;
