import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import FileView from "admin/components/resources/files/FileView";
import { useCallback, useState } from "react";
import { useRecordContext } from "react-admin";

const ViewFileField = ({ source, size = "small" }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Visibility />
      </IconButton>
      <FileView source={source} open={open} handleClose={handleClose} />
    </>
  );
};

export default ViewFileField;
