import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Collapse,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useState } from "react";
import { useSidebarState } from "react-admin";
import { NavLink as NavLinkBase } from "react-router-dom";

const ListItemButton = styled(MuiListItemButton)(() => ({
  "&.active .MuiTypography-root": {
    fontWeight: "bold",
  },
}));

const MenuItemLink = ({
  icon: Icon,
  isRoot,
  title,
  isActive = false,
  isRoute,
}) => {
  const [open] = useSidebarState();
  return (
    <ListItemButton
      dense
      selected={isActive}
      sx={{ marginX: 1, marginY: isRoute ? 1 : 0, borderRadius: 1 }}
    >
      {Icon && (
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{ fontWeight: isRoot ? "bolder" : "regular" }}
        primary={title}
        sx={{ opacity: open ? 1 : 0 }}
      />
    </ListItemButton>
  );
};

const MenuItem = ({ item, isRoot = true }) => {
  const { route, icon, title, children, defaultOpen } = item;
  const [collapseOpen, setCollapseOpen] = useState(defaultOpen);

  const handleMenuClick = () => setCollapseOpen(!collapseOpen);
  const Icon = icon;
  return (
    <>
      {route ? (
        <NavLinkBase
          to={route}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {({ isActive }) => (
            <MenuItemLink
              title={title}
              icon={icon}
              isRoot={isRoot}
              isActive={isActive}
              isRoute
            />
          )}
        </NavLinkBase>
      ) : (
        <ListItemButton dense={!isRoot} onClick={handleMenuClick}>
          {!isRoot && (
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText
            primaryTypographyProps={{
              fontWeight: isRoot ? "bolder" : "regular",
            }}
            primary={title}
          />
          <ListItemSecondaryAction>
            {collapseOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </ListItemSecondaryAction>
        </ListItemButton>
      )}
      {children?.length > 0 && (
        <Collapse in={collapseOpen}>
          {children.map((menuItem) => (
            <MenuItem
              key={menuItem?.title}
              item={menuItem}
              isRoot={false}
              onMenuClick={handleMenuClick}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

export default MenuItem;
