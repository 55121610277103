import { Add } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import FilterListIcon from "@mui/icons-material/FilterList";
import Person2Icon from "@mui/icons-material/Person2";
import {
  Button,
  Container,
  Dialog,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ResourceCreator } from "admin/components/form/ResourceForm";
import CollaboratorListAside from "admin/components/resources/collaborator/list/aside";
import PageTitle from "admin/layout/PageTitle";
import { useCallback, useState } from "react";
import {
  BooleanField,
  Datagrid,
  List,
  ReferenceField,
  ShowButton,
  SimpleList,
  TextField,
} from "react-admin";

const ListActions = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Grid container direction={"row-reverse"}>
      <Grid item>
        <Button color={"primary"} onClick={handleOpen} startIcon={<Add />}>
          Nouveau collaborateur
        </Button>
        <ResourceCreator
          FormContainerProps={{
            open,
            maxWidth: "md",
            fullWidth: true,
            onClose: handleClose,
          }}
          FormProps={{ handleClose }}
        />
      </Grid>
    </Grid>
  );
};

const ListFilters = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Grid container>
      <Grid item>
        <Button
          color={"primary"}
          onClick={handleOpen}
          startIcon={<FilterListIcon />}
        >
          Filtrer
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <CollaboratorListAside mobile />
        </Dialog>
      </Grid>
    </Grid>
  );
};

export const CollaboratorListLayout = ({ withCompany = true }) => {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source={"lastname"} label={"Nom"} />
      <TextField source={"firstname"} label={"Prénom"} />
      <TextField source={"email"} label={"Adresse e-mail"} />
      <BooleanField source={"active"} label={"Actif"} />
      {withCompany && <TextField source={"company.name"} label={"Client"} />}
      <ShowButton resource={"collaborators"} label={""} />
    </Datagrid>
  );
};

const CollaboratorList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowStyle = (record, index) => ({
    backgroundColor: index % 2 !== 0 && grey[100],
  });

  return (
    <Container maxWidth={"xl"}>
      <List
        aside={!isSmall && <CollaboratorListAside />}
        component={Paper}
        hasShow={false}
        actions={isSmall ? <ListFilters /> : <ListActions />}
        hasEdit={false}
        empty={false}
        title={<PageTitle>Liste des usagers</PageTitle>}
      >
        {isSmall ? (
          <SimpleList
            primaryText={
              <Grid container spacing={0.5}>
                <Grid item>
                  <Person2Icon />
                </Grid>
                <Grid item>
                  <TextField source={"firstname"} />
                </Grid>
                <Grid item>
                  <TextField source={"lastname"} />
                </Grid>
              </Grid>
            }
            secondaryText={
              <Grid container spacing={0.5} alignItems={"center"}>
                <Grid item>
                  <BusinessIcon />
                </Grid>
                <Grid item>
                  <ReferenceField
                    link={false}
                    reference={"companies"}
                    source={"company"}
                  >
                    <TextField source={"name"} />
                  </ReferenceField>
                </Grid>
              </Grid>
            }
            tertiaryText={
              <Grid container spacing={0.5}>
                <Grid item>
                  <EmailIcon />
                </Grid>
                <Grid item>
                  <TextField source={"email"} />
                </Grid>
              </Grid>
            }
            linkType={"show"}
            rowStyle={rowStyle}
          />
        ) : (
          <CollaboratorListLayout />
        )}
      </List>
    </Container>
  );
};

export default CollaboratorList;
