import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  TextField,
} from "react-admin";
import StatusTogglerButton from "../../general/buttons/StatusTogglerButton";

const UserList = () => (
  <List resource={"users"}>
    <Datagrid bulkActionButtons={false}>
      <TextField source={"lastname"} label={"Nom"} />
      <TextField source={"firstname"} label={"Prénom"} />
      <BooleanField source={"active"} label={"Actif"} />
      <StatusTogglerButton resource={"users"} />
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
