import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const CloseDialogButton = ({ handleClose }) => {
  return (
    <IconButton
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
      onClick={handleClose}
    >
      <Close />
    </IconButton>
  );
};

export default CloseDialogButton;
