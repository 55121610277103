import { Grid, Typography } from "@mui/material";
import BooleanTextField from "admin/components/general/fields/BooleanTextField";
import { DateField, TextField, useRecordContext } from "react-admin";

const InlineField = ({
  source,
  icon: Icon,
  label,
  type,
  textDirection = "row",
  additionalProps,
}) => {
  const record = useRecordContext();
  let Component;
  if (type === "boolean") {
    Component = BooleanTextField;
  } else if (type === "date") {
    Component = DateField;
  } else {
    Component = TextField;
  }

  if (!record[source]) {
    return <></>;
  }

  return (
    <Grid container alignItems={"center"} spacing={1}>
      {Icon && (
        <Grid item>
          <Icon fontSize={"small"} />
        </Grid>
      )}
      <Grid item>
        <Grid container direction={textDirection}>
          <Grid item>
            <Component
              variant={"overline"}
              component={Typography}
              source={source}
              {...additionalProps}
            />
          </Grid>
          {label && (
            <Grid item>
              <Typography component={"span"} variant={"overline"}>
                &nbsp;{label}&nbsp;
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InlineField;
