import { Business } from "@mui/icons-material";
import { Card, CardContent } from "@mui/material";
import React from "react";
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  useGetList,
} from "react-admin";

const CompanyFilterItem = ({ company: { "@id": IRI, name } }) => {
  return <FilterListItem label={name} value={{ company: IRI }} />;
};

const CompanyFilterIterator = React.memo(() => {
  const { data, isLoading } = useGetList("companies");
  return (
    <>
      {!isLoading &&
        data.map((company) => (
          <CompanyFilterItem
            company={company}
            key={`filter_${company["@id"]}`}
          />
        ))}
    </>
  );
});

const CollaboratorListAside = ({ mobile }) => {
  return (
    <Card sx={!mobile && { order: 1, ml: 2, mt: 8, width: 300 }}>
      <CardContent>
        <FilterLiveSearch
          fullWidth
          source={"firstname"}
          variant={"outlined"}
          label={"Prénom"}
        />
        <FilterLiveSearch
          fullWidth
          source={"lastname"}
          variant={"outlined"}
          label={"Nom"}
        />
        <FilterList fullWidth label="Entreprise" icon={<Business />}>
          <CompanyFilterIterator />
        </FilterList>
      </CardContent>
    </Card>
  );
};
export default CollaboratorListAside;
