import {
  EmailOutlined,
  PeopleOutline,
  PermContactCalendarOutlined,
  PhoneOutlined,
  PlaceOutlined,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import PageTitle from "admin/layout/PageTitle";
import { TextField, useRecordContext } from "react-admin";

const TitleSection = () => {
  const { name, contactEmail, contactPhone } = useRecordContext();
  return (
    <>
      <Grid container alignItems={"center"} spacing={2}>
        <Grid item>
          <Grid container direction={"column"}>
            <Grid item>
              <PageTitle>{name}</PageTitle>
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} spacing={1}>
                <Grid item>
                  <PlaceOutlined />
                </Grid>
                <Grid item>
                  <TextField
                    fontWeight={"uppercase"}
                    variant={"subtitle1"}
                    source={"street"}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    variant={"subtitle1"}
                    textTransform={"uppercase"}
                    source={"postcode"}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    textTransform={"uppercase"}
                    variant={"subtitle1"}
                    fontWeight={"bolder"}
                    source={"city"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <PeopleOutline />
                    </Grid>
                    <Grid item>
                      <TextField source={"size"} /> collaborateurs
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <PermContactCalendarOutlined />
                    </Grid>
                    <Grid item>
                      <TextField
                        source={"contactName"}
                        emptyText={"Contact non renseigné"}
                      />
                    </Grid>
                    {contactPhone && (
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <PhoneOutlined />
                          </Grid>
                          <Grid item>
                            <TextField source={"contactPhone"} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {contactEmail && (
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <EmailOutlined />
                          </Grid>
                          <Grid item>
                            <TextField source={"contactEmail"} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TitleSection;
