import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
} from "@mui/material";
import CloseDialogButton from "admin/components/general/ui/dialog/CloseDialogButton";
import DepartmentCreator from "admin/components/resources/departments/form/DepartmentCreator";
import TeamCreator from "admin/components/resources/teams/form/TeamCreator";
import { useCallback, useEffect, useState } from "react";

const CreatorWizard = ({ open, handleClose }) => {
  const [wizardOpen, setWizardOpen] = useState(false);
  const [formType, setFormType] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setWizardOpen(open);
  }, [open, setWizardOpen]);

  const handleFormSelection = useCallback(
    (type) => {
      setFormType(type);
      setShowForm(true);
      setWizardOpen(false);
      handleClose();
    },
    [setFormType, setWizardOpen]
  );

  const handleFormClose = useCallback(() => {
    setFormType(null);
    setShowForm(false);
    handleClose();
  }, [setFormType, setShowForm, handleClose]);

  return (
    <>
      <Dialog
        open={wizardOpen}
        maxWidth={"xs"}
        fullWidth
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <DialogTitle align={"center"} fontWeight={"bolder"}>
          Que voulez-vous créer ?
        </DialogTitle>
        <CloseDialogButton handleClose={handleClose} />
        <DialogContent>
          <Grid
            container
            direction={"column"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid item>
              <Button
                size={"large"}
                onClick={() => handleFormSelection("team")}
              >
                Une équipe
              </Button>
            </Grid>
            <Grid item>
              <Alert severity={"info"}>
                Un service ne peut pas contenir de collaborateurs. Son but est
                de pouvoir grouper plusieurs services ou équipes ensemble.
              </Alert>
            </Grid>
            <Grid item>
              <Button
                size={"large"}
                onClick={() => handleFormSelection("department")}
              >
                Un service
              </Button>
            </Grid>
            <Grid item>
              <Alert severity={"info"}>
                Une équipe peut contenir des collaborateurs, mais ne peut pas
                contenir d'autres éléments. Il s'agit du plus petit élément
                d'une arborescence.
              </Alert>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <DepartmentCreator
        open={formType === "department" && showForm}
        onClose={handleFormClose}
      />
      <TeamCreator
        open={formType === "team" && showForm}
        onClose={handleFormClose}
      />
    </>
  );
};

export default CreatorWizard;
