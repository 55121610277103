import { Typography } from "@mui/material";
import { useRecordContext } from "react-admin";

const BooleanTextField = ({ text, source, textVariant = "body2" }) => {
  const record = useRecordContext();
  return (
    <Typography variant={textVariant} component={"span"}>
      {record && record[source] ? text.true : text.false}
    </Typography>
  );
};

export default BooleanTextField;
