import { Avatar } from "@mui/material";
import { stringAvatar } from "admin/const/utils";
import { useRecordContext } from "react-admin";

const AvatarField = ({ source, size = 24, sx }) => {
  const record = useRecordContext();
  const style = {
    height: size,
    width: size,
    fontSize: size / 2,
    borderRadius: "5px",
    ...sx,
  };
  if (!record) {
    return <></>;
  }
  return record[source] ? (
    <Avatar {...stringAvatar(record[source], style)} />
  ) : (
    <></>
  );
};

export default AvatarField;
