import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Grow,
} from "@mui/material";
import CloseDialogButton from "admin/components/general/ui/dialog/CloseDialogButton";
import BudgetIterator from "admin/components/resources/collaborator/show/budget/BudgetIterator";
import React from "react";
import { ReferenceManyField } from "react-admin";

const BudgetDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      TransitionComponent={Grow}
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      maxWidth={"lg"}
      fullWidth={true}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>
        Gestion du budget
        <CloseDialogButton handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Grid justifyContent={"center"} container spacing={2}>
          <Grid item xs={12} md={5}>
            <ReferenceManyField
              reference={"collaborator_incomes"}
              target={"collaborator"}
            >
              <BudgetIterator title={"Revenus"} typeResource={"income_types"} />
            </ReferenceManyField>
          </Grid>
          <Grid item>
            <Divider orientation={"vertical"} />
          </Grid>
          <Grid item xs={12} md={5}>
            <ReferenceManyField
              reference={"collaborator_outcomes"}
              target={"collaborator"}
            >
              <BudgetIterator
                title={"Charges"}
                typeResource={"outcome_types"}
              />
            </ReferenceManyField>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(BudgetDialog);
