import React from 'react';
import {TextField} from "react-admin";
import {Alert, AlertTitle, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const NotesContainer = () => {
    return (
        <Paper>
            <Box p={2}>
                <Typography variant={"h5"}>Notes internes</Typography>
                <Alert severity={"info"}>
                    <AlertTitle>Pour information</AlertTitle>
                    Ceci est une section de notes réservées et invisibles au client. Pour les mettre à jour, cliquer sur
                    éditer
                </Alert>
                <Box mt={2} p={5} sx={{border: '1px solid #000'}}>
                    <TextField source={"notes"} sx={{whiteSpace: "pre-wrap"}}></TextField>

                </Box>
            </Box>

        </Paper>
    );
};

export default NotesContainer;