import { Box, DialogContentText, Grid } from "@mui/material";
import { emailValidation } from "admin/const/validation";
import { useEffect } from "react";
import { TextInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = [];

const CollaboratorStepThree = ({ activeStep, currentStep, goToNextStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();

  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });

      if (canGoNext) {
        goToNextStep();
      }
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);
  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Adresse postale
      </DialogContentText>
      <TextInput
        size={"large"}
        fullWidth
        name={"street"}
        source={"street"}
        label={"Numéro et nom de voie"}
      />
      <Grid container>
        <Grid item md={4}>
          <TextInput
            size={"laege"}
            name={"postcode"}
            source={"postcode"}
            label={"Code Postal"}
          />
        </Grid>
        <Grid item md={8}>
          <TextInput
            size={"large"}
            fullWidth
            name={"city"}
            source={"city"}
            label={"Ville"}
          />
        </Grid>
      </Grid>
      <DialogContentText align={"center"} variant={"overline"}>
        Téléphones et e-mail
      </DialogContentText>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextInput
            size={"large"}
            fullWidth
            source={"phone"}
            name={"phone"}
            label={"Téléphone fixe"}
          />
        </Grid>

        <Grid item md={6}>
          <TextInput
            size={"large"}
            fullWidth
            name={"mobilePhone"}
            source={"mobilePhone"}
            label={"Téléphone portable"}
          />
        </Grid>
      </Grid>
      <Box mb={1}>
        <TextInput
          size={"large"}
          validate={emailValidation}
          fullWidth
          name={"email"}
          source={"email"}
          label={"Adresse e-mail"}
        />
      </Box>
    </>
  );
};

export default CollaboratorStepThree;
