import { Box, CardContent, Grid, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import AvatarField from "admin/components/general/fields/AvatarField";
import { ReferenceField, TextField } from "react-admin";

const ReminderExpand = ({ record, record: { done, priority } }) => {
  return (
    <Paper>
      <Box bgcolor={grey[200]} p={1}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <ReferenceField link={false} reference={"users"} source={"user"}>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <AvatarField source={"fullName"} />
                </Grid>
                <Grid item>
                  <TextField source={"fullName"} />
                </Grid>
              </Grid>
            </ReferenceField>
          </Grid>
        </Grid>
      </Box>
      <CardContent>
        <Typography variant={"overline"} component={"p"}>
          A faire
        </Typography>
        <Typography>{record.description}</Typography>
      </CardContent>
    </Paper>
  );
};

export default ReminderExpand;
