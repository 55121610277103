import { Add } from "@mui/icons-material";
import { Button, Card, Container, Grid, useMediaQuery } from "@mui/material";
import { grey } from "@mui/material/colors";
import CompanyCreator from "admin/components/resources/companies/form/CompanyCreator";
import PageTitle from "admin/layout/PageTitle";
import { useCallback, useState } from "react";
import { Datagrid, List, ShowButton, SimpleList, TextField } from "react-admin";

const ListActions = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return (
    <Grid container direction={"row-reverse"}>
      <Grid item>
        <Button color={"primary"} onClick={handleOpen} startIcon={<Add />}>
          Nouveau client
        </Button>
        <CompanyCreator open={open} handleClose={handleClose} />
      </Grid>
    </Grid>
  );
};

const CompaniesList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowStyle = (record, index) => ({
    backgroundColor: index % 2 !== 0 && grey[100],
  });

  return (
    <Container maxWidth={"xl"}>
      <List
        component={Card}
        hasShow={false}
        actions={<ListActions />}
        hasEdit={false}
        empty={false}
        title={<PageTitle>Liste des clients</PageTitle>}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record.name}
            linkType="show"
            rowStyle={rowStyle}
          />
        ) : (
          <Datagrid bulkActionButtons={false}>
            <TextField source={"name"} label={"Nom"} />
            <ShowButton resource={"companies"} label={""} />
          </Datagrid>
        )}
      </List>
    </Container>
  );
};

export default CompaniesList;
