import { ResourceCreator } from "admin/components/form/ResourceForm";
import { ResourceContextProvider } from "react-admin";

const ReminderCreator = ({ open, onClose, initialValues }) => {
  return (
    <ResourceContextProvider value={"reminders"}>
      <ResourceCreator
        FormContainerProps={{ open, maxWidth: "md", fullWidth: true, onClose }}
        FormProps={{ handleClose: onClose }}
        initialValues={initialValues}
        resource={"reminders"}
      />
    </ResourceContextProvider>
  );
};

export default ReminderCreator;
