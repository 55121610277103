import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import FormTypeContext from "admin/components/general/context/FormTypeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { RecordContextProvider, SaveButton } from "react-admin";

const StepperForm = ({
  formSteps,
  formTitle,
  showForm,
  formId,
  saveButtonText,
  afterSuccessContent,
  handleClose,
  user,
}) => {
  const [contextAwareFormSteps, setContextAwareFormSteps] = useState([]);
  const formType = useContext(FormTypeContext);

  useEffect(() => {
    setContextAwareFormSteps(
      formSteps.filter((step) => step[formType] === true)
    );
  }, [formSteps, setContextAwareFormSteps, formType]);

  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [showSuccessContent, setShowSuccessContent] = useState(false);
  const [createdRecord, setCreatedRecord] = useState(false);

  const handleShowAfterSuccessContent = useCallback(() => {
    if (afterSuccessContent) {
      setShowSuccessContent(true);
    } else {
      handleClose();
    }
  }, [afterSuccessContent, handleClose, setShowSuccessContent]);

  const onSuccess = useCallback(
    (data) => {
      setCreatedRecord(data);
      setSuccess(true);
    },
    [setSuccess, setCreatedRecord]
  );

  const goToNextStep = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep, setActiveStep]);

  // const formType = useFormTypeContext();
  return (
    <>
      <Grow
        in={!success}
        onExited={handleShowAfterSuccessContent}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div>
          <DialogTitle align={"center"} fontWeight={"bolder"}>
            {formTitle}
          </DialogTitle>
          <DialogContent>
            <Stepper orientation={"vertical"} activeStep={activeStep}>
              {contextAwareFormSteps.map(
                ({ title, stepComponent: StepComponent }, index) => {
                  return (
                    <Step active={activeStep >= index} key={title}>
                      <StepLabel>
                        <Typography
                          gutterBottom
                          fontWeight={"bolder"}
                          variant={"subtitle1"}
                        >
                          {title}
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <StepComponent
                          activeStep={activeStep}
                          goToNextStep={goToNextStep}
                          currentStep={index}
                        />
                      </StepContent>
                    </Step>
                  );
                }
              )}
            </Stepper>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={"center"}>
              <Grid item>
                <SaveButton
                  type={"button"}
                  mutationOptions={{ onSuccess }}
                  form={formId}
                  label={saveButtonText}
                  variant={"text"}
                  disabled={activeStep !== contextAwareFormSteps.length}
                ></SaveButton>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </Grow>
      {afterSuccessContent && (
        <Grow in={showSuccessContent} mountOnEnter={true} unmountOnExit={true}>
          <div>
            <RecordContextProvider value={createdRecord}>
              {afterSuccessContent}
            </RecordContextProvider>
          </div>
        </Grow>
      )}
    </>
  );
};

export default StepperForm;
