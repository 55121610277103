import { Box, CssBaseline } from "@mui/material";
import AdminAppBar from "admin/AdminAppBar";
import { isAdmin } from "admin/const/permissions";
import AppDrawer from "admin/layout/AppDrawer";
import LoadingPage from "admin/layout/LoadingPage";
import Menu from "admin/layout/Menu";
import DashboardAppBar from "dashboard/DashboardAppBar";
import { useEffect, useState } from "react";
import {
  Layout,
  useDataProvider,
  useGetPermissions,
  useRedirect,
} from "react-admin";

const AppLayout = (props) => {
  const { introspect } = useDataProvider();
  const permissions = useGetPermissions()();
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const redirect = useRedirect();
  useEffect(() => {
    if (!initialized && !loading) {
      setLoading(true);
      introspect().then((data) => {
        setInitialized(true);
        setLoading(false);
        if (!isAdmin(permissions)) {
          redirect("/");
        }
      });
    }
  }, [introspect, initialized, setInitialized, permissions, redirect]);
  return initialized ? (
    <>
      <CssBaseline />
      {isAdmin(permissions) ? (
        <Layout
          {...props}
          sidebar={AppDrawer}
          appBar={AdminAppBar}
          menu={Menu}
        />
      ) : (
        <Layout {...props} appBar={DashboardAppBar} sidebar={Box} menu={Box} />
      )}

      {/*<ReactQueryDevtools/>*/}
    </>
  ) : (
    <LoadingPage />
  );
};

export default AppLayout;
