import EditButton from "admin/components/general/buttons/EditButton";
import UpdateReminderStatusButton from "admin/components/general/buttons/UpdateReminderStatusButton";
import ConditionColoredField from "admin/components/general/fields/ConditionColoredField";
import ReminderExpand from "admin/components/resources/reminders/list/ReminderExpand";
import ReminderIconField from "admin/components/resources/reminders/list/ReminderIconField";
import { memo } from "react";
import { Datagrid, DateField, ReferenceField, TextField } from "react-admin";

const ReminderListLayout = ({ showFolder = false, showClient = false }) => {
  const rowCondition = (record) => !record.done && record.sent;

  return (
    <Datagrid
      bulkActionButtons={false}
      expandSingle
      expand={<ReminderExpand showFolder={showFolder} />}
    >
      <ReminderIconField source={"priority"} label={"Priorité"} />
      <ConditionColoredField
        component={DateField}
        condition={rowCondition}
        source={"sendAt"}
        label={"Date"}
      />
      <TextField
        color={"inherit"}
        condition={rowCondition}
        source="type.label"
      />
      {showClient && (
        <ReferenceField
          link={false}
          reference={"collaborators"}
          source={"collaborator"}
          label={"Usager"}
        >
          <TextField source={"fullName"} />
        </ReferenceField>
      )}
      {showClient && (
        <ReferenceField
          link={false}
          reference={"collaborators"}
          source={"collaborator"}
          label={"Client"}
        >
          <ReferenceField
            link={false}
            reference={"companies"}
            source={"company"}
          ></ReferenceField>
        </ReferenceField>
      )}
      <UpdateReminderStatusButton
        variant={"text"}
        label={"Statut"}
        source={"done"}
      />
      <EditButton />
    </Datagrid>
  );
};

export default memo(ReminderListLayout);
