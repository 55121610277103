import { Grid } from "@mui/material";
import { requiredValidation } from "admin/const/validation";
import { AutocompleteInput, ReferenceInput, TextInput } from "react-admin";

const TeamForm = ({ filter, defaultValues }) => {
  return (
    <Grid container direction={"column"} spacing={1} sx={{ width: "100%" }}>
      <Grid item>
        <TextInput
          source={"name"}
          label="Nom de l'équipe"
          autoFocus
          defaultValue={filter}
          fullWidth
          size={"large"}
          helperText={false}
          validate={requiredValidation}
        />
      </Grid>
      <Grid item>
        <ReferenceInput
          filter={defaultValues}
          source={"department"}
          reference={"departments"}
          name={"parent"}
        >
          <AutocompleteInput
            name={"department"}
            size={"large"}
            label={"Equipe parent"}
            filterToQuery={(search) => ({ name: search })}
            validate={requiredValidation}
          />
        </ReferenceInput>
      </Grid>
    </Grid>
  );
};

export default TeamForm;
