import {
  AccessAlarm,
  Notifications,
  QuestionMark,
  Spa,
  WarningAmber,
} from "@mui/icons-material";

// export const priority = ['Non spécifié', 'Bas', 'Moyen', 'Élevé', 'Urgence'];
export const priorityArray = [
  {
    label: "Non spécifié",
    icon: QuestionMark,
    severity: "info",
  },
  {
    label: "Bas",
    icon: Spa,
    severity: "info",
  },
  {
    label: "Moyen",
    icon: AccessAlarm,
    severity: "warning",
  },
  {
    label: "Élevé",
    icon: Notifications,
    severity: "error",
  },
  {
    label: "Urgence",
    icon: WarningAmber,
    severity: "error",
  },
];
export const isEmergency = (priority) => priority === 4;
