import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CloseDialogButton from "admin/components/general/ui/dialog/CloseDialogButton";
import { emailValidation, firstnameValidation } from "admin/const/validation";
import { SaveButton, TextInput, useRecordContext } from "react-admin";

export const DashboardAccessFormContainer = ({
  formId,
  handleClose,
  department,
}) => {
  console.log(handleClose);
  const record = useRecordContext();
  return (
    <Box p={2}>
      <CloseDialogButton handleClose={handleClose} />
      <DialogTitle align={"center"} fontWeight={"bolder"}>
        {record
          ? `Éditer l'accès de ${record.firstName} ${record.lastName}`
          : "Créer un nouvel accès tableau de bord"}
      </DialogTitle>
      <DialogContent>
        <Box mt={1}>
          <TextInput
            validate={firstnameValidation}
            name={"firstname"}
            source={"firstname"}
            size={"large"}
            fullWidth
            label={"Prénom"}
          />
        </Box>
        <Box mt={1}>
          <TextInput
            validate={firstnameValidation}
            name={"lastname"}
            source={"lastname"}
            size={"large"}
            fullWidth
            label={"Nom"}
          />
        </Box>
        <Box mt={1}>
          <TextInput
            validate={emailValidation}
            name={"email"}
            source={"email"}
            size={"large"}
            fullWidth
            label={"Adresse e-mail"}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SaveButton
          type={"submit"}
          form={formId}
          label={record ? "Mettre à jour" : "Créer le tableau de bord"}
          variant={"text"}
        ></SaveButton>
      </DialogActions>
    </Box>
  );
};
