import { LoadingButton } from "@mui/lab";
import { CircularProgress, Grow } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  LengthAndStrengthIndicator,
  PasswordValidator,
} from "admin/components/pages/login/ResetPasswordSection";
import { entrypoint, httpClient } from "admin/config/dataProvider";
import { passwordValidation } from "admin/const/validation";
import React, { useEffect, useState } from "react";
import { PasswordInput, SimpleForm, useLogin, useNotify } from "react-admin";
import { Navigate, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const passwordStrengthFormatter = {
  strong: {
    color: "success",
    label: "Fort",
  },
  medium: {
    color: "warning",
    label: "Suffisant",
  },
  low: {
    color: "error",
    label: "Insuffisant",
  },
};

const InitializePasswordSection = () => {
  const query = useQuery();
  const token = query.get("token");
  const expires = query.get("expires") || 0;
  const firstname = query.get("firstname");
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [canCheck, setCanCheck] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showLoginSpinner, setShowLoginSpinner] = useState(false);
  const notify = useNotify();
  const login = useLogin();

  useEffect(() => {
    const expireDate = new Date(expires * 1000);
    setExpired(expireDate < new Date());
  }, [setExpired]);

  if (!token || expired) {
    notify("Le lien n'est pas ou plus valide", { type: "error" });
    return <Navigate to={"/login"} />;
  }

  const handleSubmit = ({ plainPassword }) => {
    setLoading(true);
    httpClient(
      `${entrypoint}/security/password/reset`,
      {
        method: "POST",
        body: JSON.stringify({ plainPassword, token }),
      },
      true
    )
      .then(({ json }) => {
        const { email } = json;
        setLoading(false);
        setSuccess(true);

        setTimeout(() => {
          login({ email, password: plainPassword }).then((r) => true);
        }, 3000);
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
      });
  };

  return (
    <Box mb={5}>
      <Box p={4}>
        <Grow
          in={!success}
          unmountOnExit={true}
          onExited={() => setShowLoginSpinner(true)}
        >
          <div>
            <Typography align={"center"} variant={"h5"} fontWeight={"bolder"}>
              Bienvenue {firstname} 😀
            </Typography>
            <Typography align={"center"}>
              Plus qu'à créer un mot de passe, et on vous ouvre les portes !
            </Typography>
            <Typography variant={"caption"} component={"p"} align={"center"}>
              8 caractères avec 1 majuscule et 1 chiffre minimum
            </Typography>
            <SimpleForm
              toolbar={false}
              reValidateMode={"onChange"}
              onSubmit={handleSubmit}
            >
              <Grid container direction={"column"} spacing={2}>
                <Grid item>
                  <PasswordInput
                    aria-autocomplete={"none"}
                    validate={passwordValidation}
                    disabled={loading}
                    size={"large"}
                    fullWidth
                    name={"plainPassword"}
                    source={"plainPassword"}
                    label={"Mot de passe"}
                    helperText={false}
                  />
                  <LengthAndStrengthIndicator setCanCheck={setCanCheck} />
                </Grid>
                <Grid item>
                  <PasswordValidator
                    disabled={!canCheck || loading}
                    setCanSubmit={setCanSubmit}
                  />
                </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                size={"large"}
                disabled={!canSubmit}
                loading={loading}
                variant={"outlined"}
              >
                J'active mon compte 😃
              </LoadingButton>
            </SimpleForm>
          </div>
        </Grow>
        <Grow in={showLoginSpinner} mountOnEnter={true} unmountOnExit={true}>
          <Box>
            <Grid container direction={"column"} alignItems={"center"}>
              <Grid item>
                <Typography
                  align={"center"}
                  variant={"h5"}
                  fontWeight={"bolder"}
                >
                  🎉 Félicitations ! 🎉
                </Typography>
                <Typography align={"center"} fontWeight={"bolder"}>
                  Votre compte a été activé
                </Typography>
              </Grid>
              <Grid item>
                <Box mt={3}>
                  <CircularProgress size={75} />
                </Box>
              </Grid>
              <Grid item>
                <Typography>Connexion</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grow>
      </Box>
    </Box>
  );
};

export default InitializePasswordSection;
