import { Container } from "@mui/material";
import CompanyStatLayout from "admin/components/resources/companies/show/graphs/CompanyStatLayout";
import {
  RecordContextProvider,
  ReferenceField,
  useGetIdentity,
} from "react-admin";

const ContractDashboard = () => {
  const { identity } = useGetIdentity();
  return (
    <Container maxWidth={"lg"}>
      <RecordContextProvider value={identity}>
        <ReferenceField source={"company"} reference={"companies"}>
          <CompanyStatLayout />
        </ReferenceField>
      </RecordContextProvider>
    </Container>
  );
};

export default ContractDashboard;
