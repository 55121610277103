import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";

const DepartmentRepartition = () => {
  const { departmentRepartition, interventionCount } = useRecordContext();
  const [showModal, setShowModal] = useState(false);
  const [unknownIntervention, setUnknownIntervention] = useState(0);

  useEffect(() => {
    setUnknownIntervention(
      interventionCount -
        departmentRepartition.reduce(
          (accumulator, department) => accumulator + department.value,
          0
        )
    );
  }, [interventionCount, departmentRepartition, setUnknownIntervention]);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item xs={12} md={12} lg={12}>
          <Box px={4}>
            <List dense>
              {departmentRepartition
                .sort((a, b) => {
                  if (a.value < b.value) {
                    return 1;
                  } else if (a.value > b.value) {
                    return -1;
                  } else {
                    return 0;
                  }
                })
                .filter((department) => department.value > 0)
                .map((department) => {
                  return (
                    <ListItem dense key={department["@id"]}>
                      <ListItemText
                        primary={department.departmentLabel}
                        secondar={department.value}
                      />
                      <ListItemSecondaryAction>
                        <ListItemText
                          primaryTypographyProps={{
                            fontWeight: "bolder",
                            fontSize: "large",
                          }}
                          primary={department.value}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              {unknownIntervention > 0 && (
                <ListItem dense>
                  <ListItemText primary={"Equipe non renseigné"} />
                  <ListItemSecondaryAction>
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "bolder",
                        fontSize: "large",
                      }}
                      primary={unknownIntervention}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
            <Grid container justifyContent={"center"}>
              <Grid item>
                <Box p={2}>
                  <Button variant={"outlined"} onClick={handleOpenModal}>
                    Consulter les services sans intervention
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Dialog open={showModal} onClose={handleCloseModal}>
              <DialogTitle>Services sans intervention</DialogTitle>
              <DialogContent>
                <List dense>
                  {departmentRepartition
                    .filter((department) => department.value === 0)
                    .map((department) => {
                      return (
                        <ListItem dense key={department["@id"]}>
                          <ListItemText primary={department.departmentLabel} />
                          <ListItemSecondaryAction>
                            <ListItemText
                              primaryTypographyProps={{
                                fontWeight: "bolder",
                                fontSize: "large",
                              }}
                              primary={department.value}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                </List>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Fermer</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DepartmentRepartition;
