import { Business } from "@mui/icons-material";
import { Card, CardContent } from "@mui/material";
import { CompanyFilterIterator } from "admin/components/helpers/filters";
import { FilterList } from "react-admin";

const RemindersListAside = ({ mobile }) => {
  return (
    <Card>
      <CardContent>
        <FilterList fullWidth label={"Entreprise"} icon={<Business />}>
          <CompanyFilterIterator />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default RemindersListAside;
