import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { requiredValidation } from "admin/const/validation";
import React from "react";
import {
  BooleanInput,
  CreateBase,
  EditBase,
  SaveButton,
  SimpleForm,
  TextInput,
  useNotify,
} from "react-admin";

const FormSubtitle = ({ children }) => {
  return (
    <Box mb={1}>
      <Typography component={"p"} variant={"overline"}>
        {children}
      </Typography>
    </Box>
  );
};

const FormContainer = ({ handleClose, record }) => {
  return (
    <>
      <DialogTitle>
        {record
          ? `Edition de ${record?.label}`
          : `Création d'une nouvelle thématique`}
      </DialogTitle>
      <DialogContent>
        <FormSubtitle>Informations générales</FormSubtitle>
        <Box mb={1}>
          <TextInput
            validate={requiredValidation}
            placeholder={"Sit corrupti non omnis et."}
            name={"label"}
            fullWidth
            source={"label"}
            label={"Intitulé"}
          />
        </Box>
        <Box mb={1}>
          <BooleanInput
            source={"active"}
            label="Inactif / Actif"
            defaultValue
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button color={"error"} onClick={handleClose}>
              Annuler
            </Button>
          </Grid>
          <Grid item>
            <SaveButton
              variant={"text"}
              form={"create_thematic_type_form"}
              label={record ? "Sauvegarder les modifications" : "Créer"}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export const ThematicTypeForm = ({ id, handleClose, open, record }) => {
  return (
    <SimpleForm
      toolbar={false}
      component={"div"}
      mode={"onBlur"}
      reValidateMode={"onChange"}
      id={id}
    >
      <Dialog open={open} onClose={handleClose}>
        <FormContainer handleClose={handleClose} record={record} />
      </Dialog>
    </SimpleForm>
  );
};

export const DialogAddThematicType = ({ open, handleClose }) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("Nouvelle thematique enregistré");
    handleClose();
  };
  return (
    <CreateBase
      resource={"thematics"}
      component={React.Fragment}
      mutationOptions={{ onSuccess }}
    >
      <ThematicTypeForm
        id={"create_thematic_type_form"}
        handleClose={handleClose}
        open={open}
      />
    </CreateBase>
  );
};

export const DialogEditThematicType = ({ open, handleClose, record }) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("Modifications enregistrées");
    handleClose();
  };

  return (
    <EditBase
      resource={"thematics"}
      component={React.Fragment}
      mutationOptions={{ onSuccess }}
    >
      <ThematicTypeForm
        id={"create_thematic_type_form"}
        handleClose={handleClose}
        open={open}
        record={record}
      />
    </EditBase>
  );
};
