import { Add } from "@mui/icons-material";
import { Box, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import { CollaboratorListLayout } from "admin/components/resources/collaborator/list/collaboratorList";
import DepartmentIterator from "admin/components/resources/departments/DepartmentIterator";
import DepartmentCreator from "admin/components/resources/departments/form/DepartmentCreator";
import TeamContainer from "admin/components/resources/teams/show/TeamContainer";
import { useCallback, useEffect, useState } from "react";
import { ReferenceManyField, ShowBase } from "react-admin";

const showAllItemsFilter = { exists: { parent: false } };
const initialRootTreeFilter = { ...showAllItemsFilter, active: true };

const TreeSection = () => {
  const [open, setOpen] = useState(false);

  const [rootTreeFilter, setRootTreeFilter] = useState();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [filter, setFilter] = useState(initialRootTreeFilter);
  const [selectedNode, setSelectedNode] = useState(null);

  const handleSelected = useCallback(
    (IRI) => {
      setSelectedNode(IRI);
    },
    [setSelectedNode]
  );

  useEffect(() => {
    if (selectedNode) {
      if (!selectedNode.includes("departments")) {
        setFilter({
          team: selectedNode,
        });
      }
    } else {
      setFilter({
        "exists[team]": false,
      });
    }
  }, [selectedNode, setFilter]);
  return (
    <Paper elevation={4}>
      <Grid container>
        <Grid item lg={4}>
          <ReferenceManyField
            reference={`departments`}
            target={"company"}
            filter={rootTreeFilter}
          >
            <DepartmentIterator handleSelected={handleSelected} />
          </ReferenceManyField>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <DepartmentCreator open={open} onClose={handleClose} />
              <Tooltip title={"Nouveau service"}>
                <IconButton onClick={handleOpen}>
                  <Add />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={8}>
          <Box p={4}>
            <ShowBase resource={"teams"} id={selectedNode}>
              <TeamContainer />
            </ShowBase>
            <ReferenceManyField
              reference={"collaborators"}
              target={"company"}
              filter={filter}
            >
              <CollaboratorListLayout withCompany={false} />
            </ReferenceManyField>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TreeSection;
