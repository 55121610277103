import { useRecordContext } from "react-admin";

import { Grid, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { Fragment } from "react";

const RADIUS_DOT = 6;
const useStyles = makeStyles((theme) => ({
  circle: {
    borderRadius: RADIUS_DOT,
    height: RADIUS_DOT * 2,
    display: "block",
    width: RADIUS_DOT * 2,
    padding: 0,
  },
}));

const ColoredCircle = ({ color }) => {
  const styles = { backgroundColor: color };
  const classes = useStyles();

  return color ? (
    <Fragment>
      <span className={classes.circle} style={styles} />
    </Fragment>
  ) : null;
};

const ActiveField = ({ source, text }) => {
  const record = useRecordContext();

  const color = record && record[source] ? green : red;

  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item>
        <ColoredCircle color={color[300]} />
      </Grid>
      <Grid item>
        <Typography variant={"overline"}>
          {record && record[source] ? text.enabled : text.disabled}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ActiveField;
