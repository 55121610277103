import { Box, DialogContentText } from "@mui/material";
import Grid from "@mui/material/Grid";
import TimePickerInput from "admin/components/general/input/CustomReactAdminInput/DateAndTimeInput/TimePickerInput";
import moment from "moment";
import { useEffect } from "react";
import { DateInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const requiredFields = [];

const InterStepOne = ({ activeStep, goToNextStep, currentStep }) => {
  const formValues = useWatch();
  const { formState, getFieldState } = useFormContext();
  const fieldValidation = requiredFields.map((field) => {
    const { error } = getFieldState(field, formState);
    return {
      field,
      error,
      empty: !formValues[field],
    };
  });

  useEffect(() => {
    if (activeStep === currentStep && formValues !== {}) {
      let canGoNext = true;
      fieldValidation.forEach((item) => {
        if (item.empty || !!item.error) {
          canGoNext = false;
        }
      });
      if (canGoNext) goToNextStep();
    }
  }, [activeStep, currentStep, goToNextStep, fieldValidation, formValues]);

  return (
    <>
      <DialogContentText align={"center"} variant={"overline"}>
        Date et durée de l'intervention
      </DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mb={1}>
            <DateInput
              defaultValue={moment()}
              source="date"
              name="date"
              label="Date de l'intervention"
              size="large"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={1}>
            <TimePickerInput
              name={"duration"}
              fullWidth
              label={"Durée de l'intervention"}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InterStepOne;
